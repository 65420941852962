import {
  Box,
  Button,
  createStyles,
  Drawer,
  IconButton,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { Close, Edit, Lock } from "@material-ui/icons";
import clsx from "clsx";
import ViewDetailsDrawerController, {Props} from "./ViewDetailsDrawerController.web";
import RegistrationFeeDetails from "../../../../packages/blocks/ordermanagement/src/RegistrationFeeDetails";

const CustomBoxDrawer = styled(Box)(({ theme }) => ({
  '& .registrationFee': {
    fontFamily: "Gotham",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#013D4F",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up(1920)]: {
      fontSize: 32,
    },
  },
  '& .disabled': {
    width: 20,
    height: 20,
    // color: '#C5CBC9',
    [theme.breakpoints.up(1920)]: {
      width: 32,
      height: 32
    },
  },
  '& .calculationGenerated': {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fontWeight: 500,
    color: "#4B4C4B",
    marginTop: '13px',
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  "& .closeBtn": {
    width: "100%",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #013D4F",
    height: '44px',
    "& span": {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#013D4F",
      textTransform: 'initial',
      [theme.breakpoints.up(1920)]: {
        fontSize: 16,
      },
    },
    [theme.breakpoints.up(1920)]: {
      height: '56px',
    },
  },
  '& .totalFeeLabel': {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
  },
  "& .totalFeeValue": {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: 28,
    },
  },
  '& .oldTotalFeeValue': {
    textDecoration: 'line-through',
    fontWeight: 400,
    fontSize: 10,
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  "& .oldPrice": {
    marginRight: "4px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: "#C5CBC9",
    textDecoration: "line-through",
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
    },
  },
  "& .feePrice": {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 20,
    },
  },
  "& .feeName": {
    fontFamily: "Gotham",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 20,
    },
  },
  "& .changeReason": {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#C5CBC9",
    marginTop: '5px',
    display: "block",
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
}));
const classes = createStyles({
  drawer: {
    "& .MuiDrawer-paperAnchorRight": {
      width: "40%",
      padding: "33px 57px 67px 57px",

      "& > div": {
        gap: "40px",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  closeContainer: {
    textAlign: "end",
  },

  registrationFeeContainer: {
    padding: "30px ",
    borderRadius: "20px",
    border: "1px solid #F0FAFA",
  },
  totalFee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    paddingTop: "15px",
    marginTop: "15px",
    borderTop: "2px solid #F0FAFA",
  },
  serviceStatus: {
    padding: "6px 12px 6px 8px",
    gap: "4px",
    borderRadius: "26px",
    background: "#EAFBFA",
    color: "#26BBB3",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
  },
  feeItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'baseline'
  },
});
export class ViewDetailsDrawer extends ViewDetailsDrawerController{
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, onCloseDrawer } = this.props;
    const { registrationFee, dealInfo } = this.state;
    return (
      <Drawer className={classes.drawer} open={open} anchor="right">
        <CustomBoxDrawer>
          <Box className={classes.closeContainer}>
            <IconButton style={{ padding: 0 }} onClick={onCloseDrawer}>
              <Close />
            </IconButton>
          </Box>
          <Box className={classes.header}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography component={"h2"} className={"registrationFee"}>
                Registration Fee <Edit  />
              </Typography>
              {dealInfo?.attributes?.fees_data.accepted_at &&
                <Box className={classes.serviceStatus}>
                  <Lock /> In Holding
                </Box>
              }
            </Box>

            <Typography component={'p'} className={'calculationGenerated'}>
              {dealInfo?.attributes?.fees_data.fee_type === 'add_on' ? 'Generated by fee calculation': 'Added, and Payable by Great Car Dealership'}
            </Typography>
          </Box>
          
          <Box className={classes.registrationFeeContainer}>
          {dealInfo?.attributes?.fees_data.fee_type === 'add_on' ? 
            <RegistrationFeeDetails
              fees={this.state.fees}
              data-test-id="registration-fee-details"
            /> : 
            <Box
              className={classes.feeItem}
              data-test-id="fee-item"
            >
              <Typography
                className={clsx("feeName", {})}
              >
                Registration Fee
                <span className={"changeReason"}>
                  *Change because: “{registrationFee.reason}”
                </span>
              </Typography>
              <Typography
                className={clsx("feePrice", {})}
              >
                <span className={"oldPrice"}>${registrationFee.oldFee}</span>
                ${registrationFee.fee}
              </Typography>
            </Box>
          }
            <Box className={classes.totalFee}>
              <Typography className={"totalFeeLabel"}>
                Total Fee
              </Typography>
              <Typography className={"totalFeeValue"}>
                ${registrationFee.totalFee}
              </Typography>
              <Typography className={"oldTotalFeeValue"}>${registrationFee.oldTotalFee}</Typography>
            </Box>
          </Box>
          
          
          <Box>
            <Button className={"closeBtn"} onClick={onCloseDrawer}>
              Close
            </Button>
          </Box>
        </CustomBoxDrawer>
      </Drawer>
    );
  }
}

export default withStyles(classes)(ViewDetailsDrawer);
