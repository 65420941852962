import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditing: boolean;
  feeEditingIndex: number;
  fees: Fee[];
  oldTotalPrice: any;
  reasonChange: string;
  dealInfo: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

// Customizable Area Start
export class Fee {
  constructor(
    public name: string,
    public changeReason: string,
    public currentPrice: string,
    public oldPrice: any
  ) {
    this.name = name;
    this.changeReason = changeReason;
    this.currentPrice = currentPrice;
    this.oldPrice = oldPrice;
  }
}
// Customizable Area End

export default class OrderSummaryRegistrationFeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDealInformationId: string = '';
  apiGetFeeDetailsId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      isEditing: false,
      fees: [],
      oldTotalPrice: null,
      feeEditingIndex: -1,
      reasonChange: "",
      dealInfo: {}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.handleFeeSave = this.handleFeeSave.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        switch (apiId) {
          case this.apiGetDealInformationId:
            this.setState({
              dealInfo: response.data
            })
            this.getFeeDetails(response.data.attributes.fees_data.id);
            break;

          case this.apiGetFeeDetailsId:
            const fees: Fee[] = [];
            response.fee_details.forEach((fee: any)=> fees.push(
              new Fee(fee.name, "", fee.value, null),
            ))
            this.setState({
              fees: fees
            })
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getDealInformation();
  }

  getFeeDetails(id: string){
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetFeeDetailsId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_multilevelapproval/hired_provider_fees/' + id
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }
  getDealInformation = () => {
    const header = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("createdDealId")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleChangeEditingStatus = () => {
    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  handleSelectFeeChange = (index: number) => {
    this.setState({
      feeEditingIndex: index
    });
    const updatedFees = [...this.state.fees];
    updatedFees[index].oldPrice = updatedFees[index].currentPrice;
    this.setState({
      fees: updatedFees,
      oldTotalPrice: this.getCurrentPrice().toFixed(2)
    });
  };

  handleFeeInputChange = (event: any, index: number) => {
    const { value } = event.target;
    const regex = /^\d*(\.\d{0,2})?$/;

    if (regex.test(value)) {
      const updatedFees = [...this.state.fees];
      updatedFees[index].currentPrice = value;

      this.setState({ fees: updatedFees });
    }
  };

  handleFeeSave(event: any, index: number) {
    if (event.key === "Enter") {
      this.setState({
        feeEditingIndex: -1
      });
      const updatedFees = [...this.state.fees];
      updatedFees[index].currentPrice = parseFloat(
        updatedFees[index].currentPrice
      ).toFixed(2);
      updatedFees[index].changeReason = this.state.reasonChange;
      this.setState({ fees: updatedFees });
    }
  }

  handleInputReason = (event: any) => {
    this.setState({
      reasonChange: event.target.value
    });
  };

  handleChangeReason = (event: any, index: number) => {
    if (event.key === "Enter" || event.type === "blur") {
      let fees = this.state.fees;
      fees[index].changeReason = event.target.value;
      this.setState({
        fees: fees,
        feeEditingIndex: -1
      });
    }
  };

  navigateToDealDetails = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectTemplatesDealDashboard"
    );
    this.send(msg);
  };

  getCurrentPrice = () => {
    let totalPrice = 0;
    this.state.fees.forEach(fee => {
      totalPrice += +fee.currentPrice;
    });
    return totalPrice;
  };

  // Customizable Area End
}
