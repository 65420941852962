import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextareaAutosize,
  styled,
  TextField,
  MenuItem,
  Menu,
  Divider,
  Modal,
  createTheme,
  Grid,
} from "@material-ui/core";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {
  notifications
} from "../../../blocks/dashboard/src/assets";
import { logo } from "../../user-profile-basic/src/assets"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { userDesign, send } from "./assets"
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { MailOutlineOutlined } from "@material-ui/icons";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import BlockController, {
  Props,
} from "./ContactusController";
import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";
import { Autocomplete } from "@material-ui/lab";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  optionForcontactus = [
    'Cancel / delete a deal',
    'Issue with Service Provider',
    'Payment issue',
    'Technical issue',
    'Other',
  ]

  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <HamburgerMenuFull navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />

          <Box sx={webStyles.logo} style={{ marginLeft: '6px' }}>
            <img style={{ marginBottom: '5px', width: '52px', height: '24px', }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStyles.headerRightSide}>
          <Box
            data-test-id=""
            sx={webStyles.notificationsButton}
          >
          <Pushnotifications navigation={this.props.navigation} id=""/>
          </Box>

          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId" />
        </Box>
      </Box>
    )
  }

  tellusmoreBox = () => {
    if (this.state.reasonContactUs === 'Other') {
      return (
        <Box style={{ marginTop: '20px' }}>
          <TextField
            data-test-id="tellmore"
            type="text"
            name="tellmore"
            label={this.state.label}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 50 }}
            InputProps={{
              style: { borderRadius: '8px', paddingLeft: '5px' },
            }}
            InputLabelProps={{
              style: { color: this.state.tellusMoreMessage ? '#000' : '#C5CBC9' }
            }}
            value={this.state.tellusMoreMessage}
            onChange={this.handleTellUsMoreMessage}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </Box>
      )
    }
    return null
  }

  toastCalll = () => {
    toast(
      <>
        <style>{`
      .Toastify__toast-theme--colored.Toastify__toast--default{
        margin: 0px ;
        padding:0px; 
      }
      .Toastify__toast-body{
        min-width: 100%;
        padding:0px;
      }
      .Toastify__toast{
        padding:0px;
        border-radius:12px;
      }
    `}</style>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', border: '1px solid #013D4F', color: ' #006064',
            borderRadius: '12px', padding: '0px 24px', height: '65px',
            boxShadow: '0px 16px 48px 0px rgba(1, 61, 79, 0.22)'
          }}>

          <Box style={{
            display: 'flex', flexDirection: 'row',
            gap: '5px', alignItems: 'center',
          }}>
            <Box>
              <img src={send} style={{ height: '16px', width: '16px' }} />
            </Box>
            <Box style={{ marginLeft: '10px' }}><Typography style={{ color: '#013D4F', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto' }}>Message Sent</Typography></Box>
          </Box>
          <Box><CloseIcon style={{ color: '#013D4F' }} /></Box>
        </Box>
      </>,
      {
        position: "top-center",
        hideProgressBar: false,
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: true,
        theme: "colored",
      });

    this.toastFalse();
  }

  leftSideForm = () => {
    const {deals, selectedDealId} = this.state;
    const CustomAutoComplete = styled(Autocomplete)({
      '& .MuiInputBase-root':{
        borderRadius: '8px',
      },
      "& .MuiFormLabel-root": {
        color: "#C5CBC9",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#4B4C4B",
      },
    })
    return (
      <Box style={{
        display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>

        <ContactUsMainBox className="maincontent" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center', flexDirection: 'column', maxWidth: '512px' }}>
          <Box>
            <Box className="gothamLightText mainHeading">Get in Touch with Us.</Box>
            <Typography className="gothamText subHeading">Get in touch with us. Fill out the form below, and we’ll respond promptly. We look forward to assisting you.</Typography>
          </Box>

          <Box style={{ marginTop: '30px' }}>
            <TextField
              data-test-id="emailtest2"
              type="email"
              name="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              InputProps={{
                style: { borderRadius: '8px', paddingLeft: '5px', color: '#C5CBC9' },
              }}
              InputLabelProps={{
                style: { color: '#000' }
              }}
              disabled={true}
              value={this.state.email}
            />
          </Box>

          <FormControl variant="outlined" style={{ width: '100%', marginTop: '20px' }}>
            <InputLabel id="demo-simple-select-outlined-label" style={{ color: this.state.reasonContactUs ? '#000' : '#C5CBC9', }}>Subject</InputLabel>
            <Select
              className="selecttest"
              data-test-id="selectoption"
              labelId="contactus-reason-label"
              id="contactus-reason"
              value={this.state.reasonContactUs}
              onChange={this.handlerReasonContactus}
              IconComponent={ExpandMoreIcon}
              label="Subject"
              style={{
                borderRadius: '8px',
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null,

                PaperProps: {
                  style: {
                    maxHeight: '800px',
                    borderRadius: '8px',
                  },
                },
              }}
              inputProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            >
              {this.optionForcontactus.map((option, index) => (
                <MenuItemStyled key={index} value={option} style={{ height: '60px', color: 'rgba(1, 61, 79, 1)', fontSize: '16px', fontWeight: 300 }}>
                  {option}
                </MenuItemStyled>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{width:'100%', marginTop:'20px'}}>
            <CustomAutoComplete
              data-test-id="deal-auto-complete"
              options={deals}
              getOptionLabel={(deal: any) => `${deal.attributes.id} / ${deal.attributes.owner_last_name}`}
              value={deals.find((deal: any) => deal.attributes.id == selectedDealId) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  this.selectDealHandler(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Deal"
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: this.state.selectedDealId ? '#000' : '#C5CBC9'}
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon />}
              
            />
          </FormControl>

          {this.tellusmoreBox()}



          <Box style={{ position: 'relative', paddingTop: '3px' }}>
            <Box
              border={1}
              borderColor="#C5CBC9"
              borderRadius={8}
              p={1}
              pr={2}
              style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', width: '95%', justifyContent: 'center' }}
            >

              <style>
                {`.textarea-red-placeholder::placeholder {
        color: #C5CBC9;
        opacity: 1;
      }`}
              </style>
              <TextareaAutosize
                className="textarea-red-placeholder"
                data-test-id="textarea"
                value={this.state.contactusMessage}
                onChange={this.handleTextAreaChangee} aria-label="minimum height" minRows={3}
                maxLength={250}
                placeholder="Please describe your issue, ask a question, or simply share your feedback."
                style={{ minHeight: '100px', marginTop: '10px', height: '100px', outline: 'none', width: '100%', border: 'none', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', paddingLeft: '5px', paddingRight: '5px', resize: 'vertical', maxHeight: '200px' }}
              />
              <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Typography className="gothamText" style={{ fontWeight: 300, fontSize: '12px', color: 'rgba(51, 65, 85, 1)' }}>{this.state.charCount}/250</Typography>
              </Box>
            </Box>
          </Box>
          <Button onClick={this.contactUsBe}
            data-test-id="submitbtnn"
            className='sendMessageButton'
            disabled={this.getDisability()} variant="contained">
            Send Message
          </Button>
          {this.state.openToast && this.toastCalll()}
        </ContactUsMainBox>

        <Box
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            maxHeight: "350px",
            maxWidth: "350px",
            zIndex: -1
          }}
        >
          <img
            src={userDesign}
            alt="Design Element"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              width: "auto",
              height: "auto",
              transform: "scaleX(-1)",
            }}
          />
        </Box>
      </Box>
    )
  }

  rightSideBox = () => {
    return (
      <ContactUsMainBox
        style={{
          backgroundColor: '#F0FAFA',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '40px',
          height: '100vh',
          borderTopLeftRadius: '150px',
          position: "relative",
          overflow: "hidden"
        }}
      >

        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography className="gothamLightText mainHeading" component={'h1'}>
            Other ways to contact us
          </Typography>

          <Typography className="gothamText subHeading" component={'h3'}>
            We're always open to your questions or feedback.
          </Typography>
        </Box>


        <RightBox
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '30px',
            gap: '50px'
          }}
        >
          <Box>
            <Typography className="phoneText" component={"p"}>
              Email
            </Typography>

            <Typography className="reachText" component={'p'}>
              For general inquiries, email us at
            </Typography>

            <Typography className="infoEmail" component={'p'}>
              info@vitu.com
            </Typography>
          </Box>
          <Box>
            <Typography className="phoneText" component={"p"}>
              Phone
            </Typography>
            <Typography className="reachText" component={'p'}
            >
              Reach out to customer support 24/7/365
            </Typography>

            <Typography className="infoEmail" component={'p'}>
              844-848-8468
            </Typography>
          </Box>
        </RightBox>

        <Box
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "auto",
            height: "auto",
            maxHeight: "350px",
            maxWidth: "350px",
          }}
        >
          <img
            src={userDesign}
            alt="Design Element"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              width: "auto",
              height: "auto",
            }}
          />
        </Box>

      </ContactUsMainBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box height={'100vh'} style={{ overflowX: 'hidden' }}>
        {this.headerr()}
        <Grid container style={{ width: '100%' }}>
          <Grid item lg={8} md={8} sm={8} xs={12} className="leftbox" style={{ width: '60%' }}>
            {this.leftSideForm()}
          </Grid>

          <StyledGrid item lg={4} md={4} sm={4} xs={12} className="rightBox" style={{ width: '40%' }}>
            {this.rightSideBox()}
          </StyledGrid>
        </Grid>
        <ToastContainer
          style={{ width: '450px' }}
        />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledGrid = styled(Grid)({
  '@media (max-width: 600px)': {
    marginTop: '15px'
  },
})

const RightBox = styled(Box)({
  "@media (max-width:1080px)": {
    flexDirection: 'column'
  },
  "@media (min-width:1081px)": {
    flexDirection: 'row'
  }

})

const MenuItemStyled = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(240, 250, 250, 1)',
  },

})

const ContactUsMainBox = styled(Box)({
  "@media (max-width:1200px)": {
    width: '60%'
  },
  "@media (min-width:1201px)": { width: '80%', },
  '& .mainHeading': {
    fontWeight: 300,
    color: '#013D4F',
    fontSize: '24px',
    lineHeight: '30px',
    "@media (min-width:1920px)": {
      fontSize: '30px',
    }
  },
  '& .subHeading': {
    marginTop: '10px',
    fontWeight: 300,
    color: '#4B4C4B',
    fontSize: '14px',
    lineHeight: '26.1px',
    "@media (min-width:1920px)": {
      fontSize: '18px',
    }
  },
  '& .sendMessageButton': {
    fontWeight: 700,
    color: '#ffffff',
    backgroundColor: '#4FC8EC',
    fontSize: '12px',
    textTransform: 'none',
    height: 44,
    width: '100%',
    marginTop: "25px",
    boxShadow:'none',
    borderRadius: 8,
    '&:disabled': {
      color: '#75D3F0',
      backgroundColor: '#F0FAFA',
    },
    "@media (min-width:1920px)": {
      fontSize: '16px',
      height: 56
    }
  },
  '& .infoEmail':{
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#013D4F',
    "@media (min-width:1920px)": {
      fontSize: '20px',
    }
  },
  '& .phoneText':{
    fontWeight: 600,
    fontSize: '14px',
    color: '#4B4C4B',
    marginBottom: '10px',
    fontFamily: 'Roboto',
    "@media (min-width:1920px)": {
      fontSize: '18px',
    }
  },
  '& .reachText':{
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#4B4C4B',
    "@media (min-width:1920px)": {
      fontSize: '14px',
    }
  }
})

const webStyles = {
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
    width: "100%",
  },
  header: {
    flexWrap: "nowrap",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #F0FAFA",
    padding: "12px 32px",
  },



  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    border: "none",
    padding: "unset",
  },

  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "0px",
    alignItems: "center",
  },

  logo: { width: "52px" },




  notificationsButton: {

    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",

    height: "24px",
    width: "24px",
    cursor: "pointer",
  },

  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",

    gap: "24px",
    alignItems: "center",
  },

  userAvatar: {

    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },




  buttonText: {
    fontFamily: "Roboto, sans-serif",

    fontSize: "14px",
    color: "#013D4F",
    fontWeight: 500,
  },

  content: {

    marginLeft: "120px",
    width: "100%",
  },

  main: {
    display: "flex",

    position: "relative",
    flexWrap: "nowrap",
  },

}
// Customizable Area End