import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export interface Deal {
  id: number;
  lastName: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    currentDealId?: number
    ownerLastName?: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    profilePhoto:string | null |undefined;
    initials:string | null;
    fullName:string;
    isOpenLogoutModal:boolean;
    role:string;
    description:string | undefined;
    isOpen: boolean,
    isExpanded : boolean,
    showMore : boolean,
    selectedDeals: number[];
    loading : boolean;
    dealsCount : number;
    isActiveTab : number | null;
    dealDataFull: Deal[];
    deals: Deal[];
    searchTerm: string;
    nbrOfDeals: number;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HamburgerMenuFullController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getContactInfoCallIdFull: string = "";
    getDealsCountCallIdFull: string = "";
    getDealsCallIdFull: string = "";
    getLatestDealsCallIdFull: string = "";
    testCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage)

        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        selectedDeals: [],
        anchorEl: null,
        profilePhoto:'',
        initials:'',
        fullName:'',
        showMore : false,
        isOpenLogoutModal:false,
        role : '',
        description: '',
        isOpen : false,
        isExpanded : false,
        loading : true,
        dealsCount : 0,
        isActiveTab : null,
        dealDataFull: [],
        deals: [],
        searchTerm: '',
        nbrOfDeals: 4
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiCallId === this.getContactInfoCallIdFull)
        {
          this.setState({
            role : responseJson?.data?.attributes?.role
          })
        }
      if (apiCallId === this.getDealsCountCallIdFull) {
        this.setLoadingFalse()
        if (!responseJson.errors)
        this.setState({
          dealsCount : responseJson?.total_deals,
        })
        else 
        {
          this.setState({
            dealsCount : 0,
          })
        }
      }

      if(apiCallId === this.getLatestDealsCallIdFull){
        const latestDeals: Deal[] = [];
        responseJson.data.forEach((deal: any) => {
          latestDeals.push({
            id: deal.id,
            lastName: deal.attributes.owner_last_name
          })
        })
        this.setState({
          dealDataFull: latestDeals
        })
      }

      if(apiCallId === this.getDealsCallIdFull){
        const deals : Deal[] = [];
        responseJson.deals.data.forEach((deal: any)=> deals.push({id: deal.id, lastName: deal.attributes.owner_last_name}));
        this.setState({
          deals: deals
        })
      }
    }
      // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getContactInfo()
        this.getDealsCount()
        this.getLatestDeal()
        this.getDeals();
    }

    congnitiveActiveTab = () => {
      return this.state.dealsCount == 0 ? this.navigateToCreateDealMainFull() : this.navigateToAllDealsFull()
    }

    navigateToDealDashboard = (dealId: string) => {
      localStorage.setItem('createdDealId', dealId);
      window.location.href = "/ProjectTemplatesDealDashboard";
    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ searchTerm: event.target.value, nbrOfDeals: 4 });
    };

    showMoreHandler = () => {
      this.setState({
        nbrOfDeals: this.state.nbrOfDeals + 4
      })
    }
    
    activeTab = (num : number) => {
      if (this.state.isActiveTab !== num) 
        {
          this.setState({
            isActiveTab : num
          })
          if (num == 0) this.navigateToDashboardFull();
          if (num == 1) 
          {
            if (this.state.role === "dealer" || this.state.role === "dealer_admin") 
              {
                this.congnitiveActiveTab();
              }
            else this.navigateToAllDealsFull()  
          }
          if (num == 2) this.navigateToMyTeamFull();
        }
    }

    navigateToDashboardFull=()=>{
      const navigateData = new Message( getName( MessageEnum.NavigationMessage ));
      navigateData.addData(getName( MessageEnum.NavigationTargetMessage ), 
      "Dashboard");
      navigateData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( navigateData);
    }

    navigateToCreateDealMainFull =()=>{
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "CreateDealMain");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

    navigateToAllDealsFull =()=>{
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "AllDeals");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

    navigateToMyTeamFull= () => {
      const navigateDataMyTeam = new Message( getName( MessageEnum.NavigationMessage ));
      navigateDataMyTeam.addData(getName( MessageEnum.NavigationTargetMessage ), 
      "MyTeam");
      navigateDataMyTeam.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send(navigateDataMyTeam );
    }

    isOpen=()=>{
      this.setState(
        {
          isOpen:!this.state.isOpen,
      }
    )
    }

    toggleExpand = () => {
      this.setState(prevState => (
        {isExpanded : !prevState.isExpanded}
      )
    )
    }

    setLoadingFalse = () => {
      this.setState({loading : false})
    }

      getContactInfo = async () => {
        let tokennn = await getStorageData("authToken");
        const Webheader = {"token" : tokennn};

        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getContactInfoCallIdFull = 
        WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getContactInfoAPIEndpoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage
        );
      }

      getDealsCount=async()=>{
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token": tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDealsCountCallIdFull = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.dealsCountGetEndPoint)
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader))
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, 
          WebrequestMessage
        );
      }

      getDeals=async()=>{
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token": tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDealsCallIdFull = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_posts/deals/search_deals?search=')
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader))
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, 
          WebrequestMessage
        );
      }

      getLatestDeal=async()=>{
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token": tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getLatestDealsCallIdFull = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.latestDealsGetEndPoint)
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader))
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, 
          WebrequestMessage
        );
      }
    // Customizable Area End
  }