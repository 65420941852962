import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  open: boolean;
  onCloseDrawer: () => void;
}

interface S {
  registrationFee: RegistrationFee;
  fees: Fee[],
  dealInfo: any,
}

interface SS {}

export interface RegistrationFee {
  fee: string;
  oldFee: string;
  totalFee: string;
  oldTotalFee: string;
  status: string;
  reason: string;
}

export interface Fee {
  name: string,
  changeReason: string,
  currentPrice: string,
  oldPrice: any
}

export default class ViewDetailsDrawerController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDealInformationId: string = "";
  apiGetFeeDetailsId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      registrationFee: {} as RegistrationFee,
      fees: [],
      dealInfo:{}
    };

    this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getFeeDetails(id: string){
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetFeeDetailsId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_multilevelapproval/hired_provider_fees/' + id
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getDealInformation = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealInformationId = request.messageId;
    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/deals/" + localStorage.getItem("createdDealId")
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(request.id, request);
    return true;
  };

  async componentDidMount() {
    this.getDealInformation();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiId === this.getDealInformationId) {
        this.setState({
          registrationFee: {
            fee: response.data.attributes.fees_data?.fee_amount,
            oldFee: response.data.attributes.fees_data?.previous_fee_amount,
            status: response.data.attributes.fees_data?.status,
            totalFee: response.data.attributes.fees_data?.fee_amount,
            oldTotalFee:
              response.data.attributes.fees_data?.previous_fee_amount,
            reason: response.data.attributes.fees_data?.comment,
          },
          dealInfo: response.data
        });
        this.getFeeDetails(response.data.attributes.fees_data?.id);
      }
      if (apiId === this.apiGetFeeDetailsId){
        const fees: Fee[] = [];
        response.fee_details.forEach((fee: any)=> fees.push(
          {name: fee.name, changeReason: "", currentPrice: fee.value, oldPrice: null}
        ))
        this.setState({
          fees: fees
        })
      }
    }
  }
}
