import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  InputBase,
  Avatar,
  LinearProgress,
  withStyles,
  MenuItem,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  styled,
  Tooltip,
} from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { DeleteOutline } from "@material-ui/icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
// Customizable Area End

import ViewChatController, { configJSON, IMessageList, Props } from "./ViewChatController";


// Customizable Area Start
const PinkLinearProgress = withStyles({
  bar: {
    backgroundColor: "#013D4F", // Progress color
  },
  root: {
    backgroundColor: "#DEE4E2", // Track color
  },
})(LinearProgress);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    fontFamily: 'Roboto'
  },
}))(Tooltip);


const StyledViewChatBox = styled(Box)(({ theme }) => ({
  "& .headingText": {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "28px",
    color: '#ffffff',
    [theme.breakpoints.up(1920)]: {
      fontSize: 24,
    }
  },
  "& .subHeadingText": {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "28px",
    color: '#ffffff',
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
    }
  },
  "& .notFoundText": {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 600,
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 20,
    }
  },
  "& .deletedText": {
    color: "#989A98",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0%",
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    }
  },
  "& .messageText": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    color: "#4B4C4B",
    display: 'flex',
    overflowWrap: 'anywhere' as const,
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
    }
  },
  "& .messageTextSendingName": {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    color: "#4B4C4B",
    display: 'flex',
    overflowWrap: 'anywhere' as const,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    }
  },
  "& .messageTextName": {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    color: "#4B4C4B",
    display: 'flex',
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
    }
  },
  "& .buttonText": {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    color: "#C5CBC9",
    cursor: 'pointer',
    marginLeft: '20px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    }
  },
  "& .MenuItemText": {
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
    },
    fontSize: 12,
    fontWeight: 500
  },
  '& .newMessageBox': {
    background: "#346472",
    cursor: 'pointer',
    borderRadius: 26,
    padding: "6px 12px",
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: "5px",
    '& .newMessageText': {
      color: "#fff",
      fontSize: "12px",
      fontweight: 500,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      }
    },
    '& .newMessageArrowIcon': {
      color: "#fff",
      width: 12,
      height: 12,
      [theme.breakpoints.up(1920)]: {
        width: 16,
        height: 16,
      }
    }
  },
  "& .newMessageButton": {
    position: "absolute",
    bottom: "90px",
    left: '50%',
    transform: "translate(-50%,50%)"
  },
  "& .chatHeadingLayout": {
    backgroundColor: "#013D4F",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '10px 10px 0px 0px',
    [theme.breakpoints.up(1920)]: {
      padding: '10px',

    },
  },
  "& .iconButton": {
    color: '#fff',
    cursor: 'pointer',
    width: '18px',
    height: "18px",
    [theme.breakpoints.up(1920)]: {
      width: '1em',
      height: '1em',
    },
  },
  "& .chatAvatarNotExist": {
    width: '45px',
    height: "45px",
    border: "2px dashed #013D4F",
    backgroundColor: 'white',
    color: "#013D4F",
    fontWeight: 500 as const,
    [theme.breakpoints.up(1920)]: {
      width: '57px',
      height: '57px',
    },
  },
  "& .chatAvatarExist": {
    width: '45px',
    height: "45px",
    backgroundColor: 'white',
    [theme.breakpoints.up(1920)]: {
      width: '57px',
      height: '57px',
    },
  },

}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .addButton": {
    color: '#fff',
    background: "#4FC8EC",
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Roboto",
    borderRadius: 8,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: "none",
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
      height: 56,

    }
  },
  "& .cancelButton": {
    color: '#4C4D4C',
    background: "#fff",
    minWidth: '100px',
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Roboto",
    borderRadius: 8,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: "none",
    [theme.breakpoints.up(1920)]: {
      fontSize: 16,
      height: 56,
    }
  },
  '& .dialogHeading': {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#013D4F",
    [theme.breakpoints.up(1920)]: {
      fontSize: 24,
    }
  },
  '& .dialogSubHeading': {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#4B4C4B",
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    }
  },
  "& .dialogContent": {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    padding: "10px 40px 40px",
    overflow: 'hidden',
    [theme.breakpoints.up(1920)]: {
      gap: 16,
    }
  },
}))

// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUploadingFile = () => {
    return (
      <>
        {this.state.isUploading && (
          <Grid item xs={12} style={{ padding: "5px 35px" }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box>
                <Typography style={{ ...webStyles.buttonText, marginLeft: '0px' }} data-test-id="fileName">{this.state.fileUpload?.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" style={{ ...webStyles.buttonText, marginLeft: '0px' }}>
                  {this.state.isUploading && `${this.state.uploadProgress}%`}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Box width={'100%'}>
                <PinkLinearProgress
                  variant="determinate"
                  value={this.state.uploadProgress}
                  style={{ marginTop: "5px" }}
                />
              </Box>
              <Box>
                {this.state.uploadProgress === 100 ?
                  <IconButton data-test-id="clear_icon" onClick={this.clearUploadFile}>
                    <DeleteOutline style={webStyles.uploadCloseIcon} />
                  </IconButton>
                  :
                  <IconButton onClick={this.clearUploadFile} data-test-id="delete_icon" >
                    <ClearIcon style={webStyles.uploadCloseIcon} />
                  </IconButton>

                }
              </Box>
            </Box>

          </Grid>
        )}
      </>
    )
  }

  renderAddDocumentDailog = () => {
    const { addDocumentDialog, messageDetails } = this.state;
    return (
      <>
        <StyledDialog maxWidth='md' open={addDocumentDialog}
          onClose={this.handleCloseAddDocumentsDialog} aria-labelledby="form-dialog-title"
          PaperProps={{
            style: {
              maxWidth: "600px",
              width: '100%',
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle disableTypography style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton aria-label="close"
              data-test-id="close_dailog"
              onClick={this.handleCloseAddDocumentsDialog} >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: '0px' }}>
            <Box className={"dialogContent"}>
              <Box>
                <Typography component={'h2'} className={"dialogHeading"}>{configJSON.addDocumentsDialog.heading}</Typography>

              </Box>
              <Box>
                <Typography component={'h4'} className={"dialogSubHeading"}>
                  {configJSON.addDocumentsDialog.subHeading}<b>{this.state.fileName}</b>{configJSON.addDocumentsDialog.subHeadingEnd}
                </Typography>
              </Box>

            </Box>
          </DialogContent>
          <DialogActions style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            padding: "30px 24px"
          }}>
            <Button variant="outlined"
              onClick={this.handleCloseAddDocumentsDialog}
              data-test-id="cancel_button"
              className={"cancelButton"}
            >
              {configJSON.addDocumentsDialog.cancelButton}
            </Button>
            <Button variant="contained"
              className={"addButton"}
              data-test-id='add_document'
              onClick={() => this.addUserToChat(messageDetails.id, messageDetails.attributes.attachments[0].id)}
            >
              {configJSON.addDocumentsDialog.addButton}
            </Button>
          </DialogActions>

        </StyledDialog>
      </>
    )
  }

  noConverzationMessages = () => {
    const { chatMessageData } = this.state
    return (
      <>
        {Object.keys(chatMessageData).length === 0 &&
          <>
            <Grid item xs={12}>
              <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '230px' }}>
                <Grid item xs={12}>
                  <Typography component={'div'} align="center" className={"notFoundText"}>{configJSON.noConversationFound}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </>
    )
  }
  renderMessageText = (message: IMessageList) => {
    let messageContent;

    if (message.attributes.deleted) {
      messageContent = (
        <Typography variant="body1" className={"deletedText"} data-test-id='delete_message_text'>{configJSON.deleteMessageText}</Typography>
      );
    } else if (message.attributes.auto) {
      messageContent = (
        <Typography variant="body1"
          className="messageText"
          style={{ display: 'inline', whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{ __html: message.attributes.message }}
        />
      );
    } else {
      messageContent = (
        <Typography variant="body1" className={"messageText"}>
          {message.attributes.message}
        </Typography>
      );
    }

    return <>{messageContent}</>;
  };


  iconButtons = () => {
    const { chatfloatingWindowMinimize } = this.state
    return (
      <>
        {chatfloatingWindowMinimize ? <IconButton onClick={this.minimizeFloatingWindow} data-test-id="minimize_chat_window">
          <ExpandMoreIcon className={"iconButton"} />
        </IconButton> :
          <IconButton onClick={this.maximizeFloatingWindow} data-test-id="maxmize_chat_window">
            <ExpandLessIcon className={"iconButton"} />
          </IconButton>}
      </>
    )
  }

  renderThreeDots = (message: IMessageList) => {
    return (
      <Box position={'relative'}>
        <IconButton
          data-test-id="three_dots"
          onClick={() => this.openThreeDots(message.id)}
        >
          <MoreVertIcon style={webStyles.threedots} />
        </IconButton>
        {message.id === this.state.threeDotsId &&
          <>
            <ClickAwayListener data-test-id="click_away" onClickAway={this.closeThreeDots}>
              <Box style={{
                position: 'absolute',
                borderRadius: 8,
                padding: "7px 3px",
                right: 0,
                background: '#fff',
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008"
              }}>
                <Box padding={"0.5rem"}>
                  <MenuItem className={"MenuItemText"}
                    onClick={() => this.handleDownload(message.attributes.attachments[0].url, message.attributes.attachments[0].file_name)}
                    data-test-id="download_document"
                  >
                    <GetAppIcon style={webStyles.popupIcon} />&nbsp;
                    Download</MenuItem>
                  <MenuItem className={"MenuItemText"}
                    data-test-id="open_dailog"
                    onClick={() => this.handleAddDocumentsDialog(message)}
                  >
                    <AddIcon style={webStyles.popupIcon} />&nbsp;
                    Add To documents</MenuItem>
                </Box>
              </Box>
            </ClickAwayListener>

          </>}
      </Box>
    )
  }
  renderAutoMessages = (message: IMessageList, isCurrentUserMessage: boolean) => {
    let time = message.attributes.edited ? message.attributes.updated_at : message.attributes.created_at
    return (
      <>
        {!message.attributes.auto &&
          <>
            {message.attributes.deleted ? <Box display={'flex'} justifyContent={'flex-end'}>
              <Typography variant="body1" className="buttonText" style={{ fontWeight: 400 }}>{this.convertDate(time)}</Typography>
            </Box> : <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginTop={'5px'}>
              {isCurrentUserMessage && <Box display={'flex'} alignItems={'center'}>
                <Box>
                  <Typography variant="body1"
                    data-test-id="edit"
                    onClick={() => this.handleEditMessage(message)}
                    className="buttonText">{configJSON.editText}</Typography>
                </Box>
                <Box >
                  <Typography variant="body1"
                    data-test-id='delete'
                    onClick={() => this.handleDeleteMesssage(message)}
                    className="buttonText">{configJSON.deleteText}</Typography>
                </Box>
              </Box>}
              <Box>
                <Typography variant="body1" className="buttonText" style={{ fontWeight: 400 }}>{message.attributes.edited && "Edited"}&nbsp;{this.convertDate(time)}</Typography>
              </Box>
            </Box>}
          </>
        }
      </>
    )
  }

  renderAvatar = (message: IMessageList) => {
    return (
      <Box>
        {message.attributes.sender_details.sender_logo ?

          <Avatar src={message.attributes.sender_details.sender_logo} className={"chatAvatarExist"} /> : <Avatar

            className={"chatAvatarNotExist"}>
            {message.attributes.sender_details.sender_name
              .split(' ')
              .map((word) => word.charAt(0))
              .join('')
              .slice(0, 2)
              .toUpperCase()}
          </Avatar>}
      </Box>
    )
  }
  renderAttachments = (message: IMessageList, isCurrentUserMessage: boolean) => {
    return (
      <>
        {message.attributes.attachments.length > 0 &&
          <Box style={isCurrentUserMessage ? webStyles.userFileBox : webStyles.receiveFileBox}>
            <Box style={isCurrentUserMessage ? webStyles.userFileLayout : webStyles.receiverFileLayout}>
              <Box style={{ overflow: 'hidden', width: '250px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {message.attributes.attachments[0].file_name.length > 18 ?
                  <>
                    <LightTooltip title={message.attributes.attachments[0].file_name}>
                      <Typography className="messageTextName">
                        {message.attributes.attachments[0].file_name}</Typography>
                    </LightTooltip>
                  </>
                  :
                  <Typography className="messageTextName">
                    {message.attributes.attachments[0].file_name}</Typography>}
              </Box>
              {this.renderThreeDots(message)}
            </Box>
          </Box>
        }
      </>
    )
  }


  renderMessages = (messages: IMessageList[]) => {
    const { props } = this;
    const { role } = props;

    return (
      <>
        {messages.map((message: IMessageList) => {
          const isCurrentUserMessage = (role === message.attributes.sender_details.role)
          let messageStyle;

          if (message.attributes.auto) {
            messageStyle = isCurrentUserMessage ? webStyles.sentAutoMessageBox : webStyles.receiveAutoMessageBox;
          } else {
            messageStyle = isCurrentUserMessage ? webStyles.sentMessageBox : webStyles.receiveMessageBox;
          }
          const senderName = message.attributes.sender_details.sender_name;
          const dealershipOrProvider = message.attributes.sender_details.dealership || message.attributes.sender_details.service_provider;
          const displayText = `${senderName} - ${dealershipOrProvider}`;
          return (
            <Grid container
              style={isCurrentUserMessage ? webStyles.messageContainerBusinessOwner : webStyles.messageContainer}
            >
              <Grid item xs={12}
                style={isCurrentUserMessage ? webStyles.messageContainerBusinessOwner as React.CSSProperties : webStyles.messageContainer as React.CSSProperties}
              >
                <Grid container spacing={1} direction={isCurrentUserMessage ? "row" : "row-reverse"}>
                  <Grid item xs={10}>
                    <Box style={isCurrentUserMessage ? webStyles.messageLayout as React.CSSProperties : webStyles.receiveMessage as React.CSSProperties}>
                      <Box style={messageStyle}>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                          <Box>
                            <LightTooltip title={displayText.length > 30 ? displayText : ""}>
                              <Typography variant="body1" display="inline"
                                className="messageTextName"
                                style={webStyles.nameOverflow}>
                                {senderName}&nbsp;-&nbsp;
                                <Typography variant="body1" className={"messageTextSendingName"} >{dealershipOrProvider}</Typography>
                              </Typography>
                            </LightTooltip>
                          </Box>
                          {message.attributes.auto && <Box>
                            <Box style={isCurrentUserMessage ? webStyles.sendAutoBox : webStyles.receiveAutoBox}>
                              <Typography component={'p'} style={webStyles.autoText}>Auto</Typography>
                            </Box>

                          </Box>}
                        </Box>

                        {this.renderMessageText(message)}

                      </Box>
                      {this.renderAttachments(message, isCurrentUserMessage)}
                      {this.renderAutoMessages(message, isCurrentUserMessage)}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    {this.renderAvatar(message)}
                  </Grid>


                </Grid>



              </Grid>

            </Grid>
          )
        })}
      </>
    )
  }

  renderNewMessageButton = () => {
    return (
      <>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
          className="newMessageButton">
          <Box className="newMessageBox" onClick={this.scrollToBottom}>
            <ArrowDownwardOutlinedIcon className="newMessageArrowIcon" />
            <Typography className="newMessageText" component={'h6'}>New Message</Typography>
          </Box>
        </Box>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { state, props } = this;
    const { chatBoxType, chatDetails } = props;
    const { chatfloatingWindowMinimize, closeFloatingChat } = state;
    return (
      <>
        {closeFloatingChat && <StyledViewChatBox style={chatBoxType === "create_chat" ? webStyles.createChatBoxPreview as React.CSSProperties : webStyles.singleChatBoxPreview as React.CSSProperties}>
          <Box>
            <Box className={"chatHeadingLayout"}>
              <Box>
                <Typography component={'h6'} className={"subHeadingText"}>{chatDetails?.name}</Typography>
                <Typography component={'h6'} className={"headingText"}>{chatDetails?.dealNumber}-{chatDetails?.dealName}</Typography>
              </Box>
              <Box>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item >
                      {this.iconButtons()}
                    </Grid>
                    <Grid item >
                      <IconButton onClick={this.closeFloatingChat} data-test-id="close_chat_window">
                        <ClearIcon className={"iconButton"} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          {chatfloatingWindowMinimize && <Box style={webStyles.chatWindowContentLayout as React.CSSProperties}>
            <div ref={this.chatContainerRef} style={{ overflowY: "auto", padding: "0 20px", height: this.state.isUploading ? '170px' : '240px' }} >
              {Object.entries(this.state.chatMessageData).map(([date, messages]) => {
                return (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      style={{ marginBottom: "10px" }}
                    >
                      <Divider style={{ flexGrow: 1, backgroundColor: "#F0FAFA" }} />
                      <Typography style={webStyles.dateText}>{date}
                      </Typography>
                      <Divider style={{ flexGrow: 1, backgroundColor: "#F0FAFA" }} />
                    </Box>
                    {this.renderMessages(messages)}

                  </>
                )
              })}
              {this.noConverzationMessages()}
            </div>
            <Box style={{
              position: "absolute",
              bottom: '0px',
              width: '100%'
            }}>

              <Divider />
              {this.state.showNewMessageButton &&
                <>
                  {this.renderNewMessageButton()}
                </>
              }
              <Box style={{ minHeight: '60px', flexDirection: 'column', display: 'flex', width: '100%' }}>
                <Grid item xs={12} style={{ padding: "15px" }}>
                  <Grid container alignItems="center" spacing={1} >
                    <Grid xs={1}>
                      <AttachFileIcon
                        style={{ color: '#C5CBC9', cursor: 'pointer' }}
                        onClick={this.handleIconClick}
                        data-test-id="attach_icon"
                      />
                      {/* Hidden file input */}
                      <input
                        type="file"
                        data-test-id="file-input"
                        ref={this.fileInputRef1}
                        style={{ display: 'none' }}
                        onChange={this.handleFileUpload}
                      />

                    </Grid>
                    <Grid xs={9}>
                      <InputBase
                        data-test-id="mesage_text"
                        value={this.state.chatMessage}
                        onChange={this.handleMessage}
                        multiline
                        placeholder={configJSON.sendMessagePlaceHolder}
                        minRows={1}
                        maxRows={4}
                        style={{
                          width: '100%',
                          maxHeight: '100px',
                          resize: 'none'
                        }}
                      />

                    </Grid>
                    <Grid xs={2} >
                      <Grid container justifyContent="flex-end">
                        <IconButton
                          data-test-id="send_message"

                          onClick={() => this.handleSendMessage1(this.state.editMode)}
                        >
                          <SendIcon style={{ color: '#C5CBC9', cursor: 'pointer', width: '20px', height: '20px' }} />
                        </IconButton>
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>
                {this.renderUploadingFile()}


              </Box>
            </Box>
          </Box>}
        </StyledViewChatBox>}
        {this.renderAddDocumentDailog()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  singleChatBoxPreview: {
    position: 'fixed',
    right: "450px",
    width: '420px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  createChatBoxPreview: {
    position: 'fixed',
    right: "20px",
    width: '420px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  chatWindowContentLayout: {
    background: '#fff',
    height: '300px',
    overflowY: 'auto',
    padding: "15px 0px",
    position: 'relative',
    overflowX: 'hidden',
    zIndex: 99999
  },
  headingText: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
    color: '#ffffff'

  },
  subHeadingText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: '#ffffff'

  },
  fileBox: {
    backgroundColor: '#fff',
    borderRadius: "10px 10px 0px 0px",
    width: "90%",
    position: "absolute",
    bottom: '50px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: "1px solid #013D4F",
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
    flexDirection: 'row-reverse' as const
  },
  messageContainerBusinessOwner: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: '10px'

  },
  messageProfile: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    marginRight: '10px',
    marginLeft: "10px"
  },
  viewFileContainer: {
    background: "#E8F8FD",
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: "10px"
  },
  sentMessageBox: {
    background: "#E8F8FD",
    padding: '8px',
    minHeight: '20px',
    borderRadius: "10px 0px 10px 10px",
    display: 'flex',
    flexDirection: 'column' as const,
  },
  sentAutoMessageBox: {
    background: "#E8F8FD",
    padding: '8px',
    minHeight: '20px',
    borderRadius: "10px 0px 10px 10px",
    display: 'flex',
    flexDirection: 'column' as const,
    borderRight: "2px solid #013D4F",
    boxShadow: "0px 2px 8px 0px #00000014"

  },
  receiveAutoMessageBox: {
    background: "#fff",
    padding: '8px',
    minHeight: '20px',
    borderRadius: "0px 10px 10px 10px",
    display: 'flex',
    flexDirection: 'column' as const,
    borderLeft: "2px solid #013D4F",
    boxShadow: "0px 2px 8px 0px #00000014"

  },
  receiveMessageBox: {
    background: "#fff",
    padding: '8px',
    minHeight: '20px',
    // width: '100%',
    borderRadius: "0px 10px 10px 10px",
    display: 'flex',
    border: '1px solid #C5CBC9',
    flexDirection: 'column' as const

  },
  messageTextName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    color: "#4B4C4B",
    display: 'flex'

  },
  messageText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    color: "#4B4C4B",
    display: 'flex',
    overflowWrap: 'anywhere' as const

  }, buttonText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    color: "#C5CBC9",
    cursor: 'pointer',
    marginLeft: '20px',
    whiteSpace: 'nowrap'
  } as CSSProperties,
  messageLayout: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: "0px 20px 0px 0px"
  },
  receiveMessage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
    margin: "0px 20px 0px 10px"
  },
  viewFileText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    color: "#4B4C4B"

  },
  uploadCloseIcon: {
    color: "#4B4C4B",
    width: 23,
    height: 16

  },
  threedots: {
    width: 24,
    height: 24,
    color: "#4B4C4B"
  },
  userFileLayout: {
    width: '75%',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    background: '#E8F8FD',
    alignItems: 'center',
    padding: "10px 10px 10px 20px",
    gap: "16px",
    borderRadius: "10px 0px 10px 10px",
    marginTop: '12px'
  },
  userAutoFileLayout: {
    width: '75%',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    background: '#ffffff',
    alignItems: 'center',
    padding: "10px 10px 10px 20px",
    gap: "16px",
    borderRadius: "10px 0px 10px 10px",
    marginTop: '12px',
    marginRight: "2px solid #013D4F",
    boxShadow: "0px 2px 8px 0px #00000014"
  },
  receiverFileLayout: {
    width: '75%',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    background: '#FFF',
    alignItems: 'center',
    padding: "10px 10px 10px 20px",
    gap: "16px",
    borderRadius: " 0px 10px 10px 10px",
    border: "1px solid #C5CBC9",
    marginTop: '12px',

  },
  userFileBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    // padding: '0 8px',
  },
  receiveFileBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    // padding: '0 8px',
  },
  popupIcon: {
    color: "#013D4F",
    width: 18,
    height: 18
  },

  dateText: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Roboto",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: "#C5CBC9"
  },
  deletedText: {
    color: "#989A98",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontStyle: "italic",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0%"

  },
  autoText: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0%",
    color: "#013D4F"

  },
  receiveAutoBox: {
    background: "#fff",
    borderRadius: '15px',
    padding: "3px 6px",
  },
  sendAutoBox: {
    background: "#F0FAFA",
    borderRadius: '15px',
    padding: "3px 6px",
  },
  nameOverflow: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hiddden',
    textOverflow: "ellipsis",
    maxWidth: 230
  }
}
// Customizable Area End
