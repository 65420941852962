import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import { MoreHoriz, DeleteOutline, Edit } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export interface MenuOption {
  id: string;
  name: string;
  value: MenuAction;
  icon: React.ReactNode;
}

export enum MenuAction {
  Edit = "edit",
  Delete = "delete",
  View = "view",
  Rename = "rename",
  DownloadAllDocuments = "download all documents",
  EditDocuments = "edit documents"
}

export interface Props extends WithStyles {
  onSelectAction(value: MenuAction): void;
  menu?: { id: any; name: string; value: any; icon: any; dataTestID: string;}[];
  icon?: any;
  isDealCompletedOrDeleted: boolean;
  totalEditLeft?: number;
  totalEdit?: number;
}

const styles = createStyles({
  statusExpand: {
    marginLeft: "auto"
  },

  menu: {
    "& > div:nth-child(3)": {
      borderRadius: "8px"
    },

    "& ul": {
      padding: "15px 10px"
    }
  },

  actionLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    padding: "8px 16px",
    width: "100%",

    "&:hover": {
      backgroundColor: "#E8F8FD",
      borderLeft: "3px solid #4FC8EC",
      color: "#FFFFFF"
    },

    "& svg": {
      fill: "#333333"
    }
  },

  actionItemIcon: {
    width: 16,
    height: 16,
    [`@media (min-width:1920px)`]: {
      width: 18,
      height: 18
    },
  },

  actionItemName: {
    marginLeft: 8,
    color: "#333333"
  },

  actionItem: {
    height: "36px",
    padding: 0
  },

  menuTypo: {
    marginLeft: 10,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left",
    [`@media (min-width:1920px)`]: {
      fontSize: 16,
    },
  }
});

export class CustomMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "Edit",
      value: MenuAction.Edit,
      icon: Edit,
      dataTestID: "edit-btn"
    },

    {
      id: uuidv4(),
      name: "Delete",
      value: MenuAction.Delete,
      icon: DeleteOutline,
      dataTestID: "delete-btn"
    }
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null
    });
  };

  onChangeStatus = (value: MenuAction) => {
    this.props.onSelectAction(value);

    this.closeFilter();
  };

  render(): React.ReactNode {
    let { classes, menu, icon, isDealCompletedOrDeleted, totalEdit, totalEditLeft } = this.props;
    const { statusMenu } = this.state;

    if (menu === undefined) {
      menu = this.menu;
    }
    if (icon == undefined) {
      icon = <MoreHoriz />;
    }

    const id = uuidv4();
    return (
      <Box className={classes.detailStatus} onClick={(event)=> event.stopPropagation()}>
        <Box
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
        >
          <IconButton
            disabled={isDealCompletedOrDeleted}
            onClick={event => {
              this.openFilter(event);
            }}
          >
            {icon && icon}
          </IconButton>
        </Box>

        <Menu
          className={classes.menu}
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.detailStatus,
            "aria-labelledby": `filter-button-${id}`
          }}
        >
          {menu &&
            menu.map(option => {
              return (
                <MenuItem className={clsx(classes.actionItem)} key={option.id} disabled={option.name === 'Edit Documents' && !totalEditLeft}>
                  <Box
                    className={clsx(classes.actionLink)}
                    data-test-id={option.dataTestID}
                    onClick={() => this.onChangeStatus(option.value)}
                  >
                    {option.name==='Reupload'?
                    <img src={option.icon}
                    className={classes.actionItemIcon}
                    style={{
                      color:"#4B4C4B"
                    }}
                  />
                    
                    :<option.icon
                      className={classes.actionItemIcon}
                      style={{
                        fill: option.name === "Delete" ? "#C43937" : "#4B4C4B"
                      }}
                    />}

                    <Typography
                      className={classes.menuTypo}
                      style={{
                        color: option.name === "Delete" ? "#C43937" : "#4B4C4B"
                      }}
                    >
                      {option.name}
                      {totalEditLeft &&  option.name === 'Edit Documents'  &&
                        <span style={{color: '#C5CBC9', fontSize:'14px', fontFamily: 'Roboto', marginLeft: '8px'}}>
                         {`${totalEditLeft} out of ${totalEdit} edits left` }
                        </span>
                      }
                    </Typography>
                  </Box>
                </MenuItem>
              );
            })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomMenu);
