import {
  Box,
  createStyles,
  TextField,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import React, { ChangeEvent, KeyboardEvent } from "react";
import { Fee } from "./OrderSummaryRegistrationFeeController";
import clsx from "clsx";
import { Edit } from "@material-ui/icons";
const classes = createStyles({
  feeDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxHeight: '350px',
    overflow: 'auto'
  },

  feeItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems:'baseline'
  },

  feeName: {
    fontFamily: "Gotham",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#4B4C4B"
  },

  feePrice: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#4B4C4B"
  },

  selectedFeeTextColor: {
    color: "#1492B8 !important"
  },

  editIcon: {
    color: "#013D4F",
    marginLeft: "10px"
  },
  changeReason: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#C5CBC9",
    margin: 0,
    display: "block"
  },
  oldPrice: {
    marginRight: "4px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    color: "#C5CBC9",
    textDecoration: "line-through"
  },
  feeInput: {
    border: "0px",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    color: "#1492B8",
    textAlign: "right",
    display: "inline-block",
    "&:focus": {
      border: "0px",
      outline: "0px"
    }
  },
  reasonChangeInput: {
    marginTop: "15px",
    borderRadius: "8px",
    width: "100%",
    "& .Mui-focused": {
      color: "#4B4C4B !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C5CBC9 !important"
    },
    "& input": {
      fontSize: "16px",
      fontFamily: "Roboto",
      color: "#4B4C4B"
    },
    "& label": {
      fontSize: "16px",
      fontFamily: "Roboto",
      color: "#C5CBC9",
      paddingLeft: '10px'
    },
    "& .MuiFormHelperText-root": {
      textAlign: "end"
    }
  }
});

export interface Props extends WithStyles {
  fees: Fee[];
  feeEditingIndex?: number;
  isEditing?: boolean;
  reasonChange?: string;
  handleSelectFeeChange?: (index: number) => void;
  handleFeeInputChange?: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleFeeSave?: (event: KeyboardEvent<HTMLElement>, index: number) => void;
  handleInputReason?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleChangeReason?: (
    event: any,
    index: number
  ) => void;
}
export class RegistrationFeeDetails extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  MAX_LENGTH = 100;
  
  render(): React.ReactNode {
    const {
      classes,
      fees,
      feeEditingIndex,
      isEditing,
      reasonChange,
      handleChangeReason,
      handleInputReason,
      handleFeeSave,
      handleSelectFeeChange,
      handleFeeInputChange
    } = this.props;

    const feeSaveHandler = (event: any, index: number ) => {
      if (handleFeeSave) {
        handleFeeSave(event, index);
      }
    }

    const feeChangeHandler = (event: any, index: number) => {
      if (handleFeeInputChange) {
        handleFeeInputChange(event, index);
      }
    }

    const selectFeeChangeHandler = (index: number) => {
      if (handleSelectFeeChange) {
        handleSelectFeeChange(index);
      }
    }

    const changeReasonHandler = (event: any, index: number) => {
      if (handleChangeReason) {
        handleChangeReason(event, index);
      }
    }

    return (
      <Box className={classes.feeDetails}>
        {fees.map((fee, index) => {
          return (
            <Box data-test-id="fee-items" key={index}>
              <Box
                className={classes.feeItem}
                key={index}
                data-test-id="fee-item"
              >
                <Typography
                  className={clsx(classes.feeName, {
                    [classes.selectedFeeTextColor]: feeEditingIndex === index
                  })}
                >
                  {fee.name}
                  {fee.changeReason !== '' && (
                    <span className={classes.changeReason}>
                      *Change because: "{fee.changeReason}"
                    </span>
                  )}
                </Typography>
                <Typography
                  className={clsx(classes.feePrice, {
                    [classes.selectedFeeTextColor]: feeEditingIndex === index
                  })}
                >
                  {fee.oldPrice && fee.oldPrice !== fee.currentPrice && (
                    <span className={classes.oldPrice}>${fee.oldPrice}</span>
                  )}
                  $
                  {feeEditingIndex === index ? (
                    <input
                      data-test-id="price-input"
                      style={{
                        width: (fee.currentPrice.toString().length + 1) + "ch"
                      }}
                      value={fee.currentPrice}
                      className={classes.feeInput}
                      onChange={event => feeChangeHandler(event, index)}
                      onKeyDown={event => feeSaveHandler(event, index)}
                      contentEditable={true}
                    />
                  ) : (
                    fee.currentPrice
                  )}
                  {isEditing && (
                    <Edit
                      data-test-id="edit-price-icon"
                      onClick={()=> selectFeeChangeHandler(index)}
                      className={clsx(classes.editIcon, {
                        [classes.selectedFeeTextColor]:
                          feeEditingIndex === index
                      })}
                    />
                  )}
                </Typography>
              </Box>
              {feeEditingIndex === index && (
                <TextField
                  data-test-id="reason-change-input"
                  className={classes.reasonChangeInput}
                  id="outlined-basic"
                  label="Why are you changing this fee?"
                  variant="outlined"
                  onChange={handleInputReason}
                  onKeyDown={event => changeReasonHandler(event, index)}
                  onBlur={event => changeReasonHandler(event, index)}
                  inputProps={{
                    maxLength: this.MAX_LENGTH
                  }}
                  helperText={`${reasonChange?.length}/${this.MAX_LENGTH}`}
                />
              )}
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default withStyles(classes)(RegistrationFeeDetails);
