import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface Deal {
  id: number;
  lastName: string;
}
interface ResponseJSON {
    data: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        country_code: string;
        email: string;
        full_phone_number: string;
        phone_number: string;
        type: string;
        full_name: string;
        created_at: string;
        updated_at: string;
        is_dealer_group: boolean;
        dealer_group_name: string | null;
        role: 'dealer' | 'dealer_admin' | 'service_provider' | 'service_provider_admin';
        profile_picture: string | null;
        group_created_on: string | null;
        is_dealership_pending?: boolean;
        is_provider_pending?: boolean;
        dealership_data?: any;
        solo_dealership_id: number | null;
        is_any_dealership: boolean;
        solo_dealership_data?: {
          data: {
            id: string;
            type: string;
            attributes: {
              dealership_name: string | undefined;
              business_credentials: string;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              dealership_users: number;
              addresses: {
                data: Array<{
                  id: string;
                  type: string;
                  attributes: {
                    address: string;
                    address_type: string;
                    country: string;
                    city: string;
                    state: string;
                    zipcode: string;
                    zipcode_ext: string;
                  };
                }>;
              };
              bank_information: {
                data: any[];
              };
              dealership_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        provider_information?: {
          data: {
            id: string;
            type: string;
            attributes: {
              business_name: string | null;
              business_credentials: string | null;
              deal_type: string[];
              created_at: string;
              updated_at: string;
              business_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              shipping_address: {
                address_type: string | null;
                address: string | null;
                city: string | null;
                state: string | null;
                zipcode: string | null;
                zipcode_ext: string | null;
              };
              serviceable_locations: {
                data: any[];
              };
              contact_info: {
                name: string;
                email: string;
                phone_number: string;
              };
              banking_info: {
                data: {
                  id: string;
                  type: string;
                  attributes: {
                    bank_name: string;
                    account_number: string;
                    bank_routing_number: string;
                    branch_name: string;
                    name: string;
                    phone_number: string;
                    job_title: string;
                    created_at: string;
                    updated_at: string;
                    agree_e_sign: boolean;
                    authirization_signature: string | null;
                    voided_check: string;
                    full_signature: string;
                    initial_signature: string;
                    address: {
                      data: {
                        id: string;
                        type: string;
                        attributes: {
                          address: string;
                          address_type: string;
                          country: string | null;
                          city: string;
                          state: string;
                          zipcode: string;
                          zipcode_ext: string;
                        };
                      };
                    };
                  };
                };
              };
              service_provider_logo: string | null;
              w9_forms: any[];
            };
          };
        };
        assigned_dealership_ids: any | null;
      };
    };
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    profilePhoto:string | null |undefined;
    initials:string | null;
    fullName:string;
    isOpenLogoutModal:boolean;
    role:string;
    description:string | undefined;
    isOpen: boolean,
    isExpanded : boolean,
    selectedDeals: number[];
    searchTerm : string;
    showMore : boolean;
    isActiveTab : number | null,
    dealsCount : number,
    loading : boolean,
    dealData: Deal[],
    deals: Deal[];
    nbrOfDeals: number;
    // Customizable Area End
}

interface SS {
id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class HamburgerMenuController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getContactInfoCallId: string = "";
    getDealsCountCallId : string = "";
    getLatestDealsCallId: string = '';
    googleMaps : string = "";
    testCallId : string = "";
    getDealsCallIdFull: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        anchorEl: null,
        profilePhoto:'',
        initials:'',
        fullName:'',
        isOpenLogoutModal:false,
        role : '',
        description: '',
        isOpen : false,
        isExpanded : false,
        selectedDeals: [],
        searchTerm : '',
        showMore : false,
        isActiveTab : null,
        dealsCount : 0,
        loading : true,
        dealData: [],
        deals: [],
        nbrOfDeals: 4
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (apiCallId === this.getContactInfoCallId)
      {
        this.setState({
          role : responseJson.data.attributes.role
        })
      }
      if (apiCallId === this.getDealsCountCallId) {
        this.setLoading()
        if (!responseJson.errors)
        this.setState({
          dealsCount : responseJson?.total_deals,
        })
        else 
        {
          this.setState({
            dealsCount : 0,
          })
        }
      }
      if (apiCallId === this.googleMaps) {
        this.navigateToAllDeals();
      }

      if(apiCallId === this.getLatestDealsCallId){
        const latestDeals: Deal[] = [];
        responseJson.data.forEach((deal: any) => {
          latestDeals.push({
            id: deal.id,
            lastName: deal.attributes.owner_last_name
          })
        })
        this.setState({
          dealData: latestDeals
        })
      }

      if(apiCallId === this.getDealsCallIdFull){
        const deals : Deal[] = [];
        responseJson.deals.data.forEach((deal: any)=> deals.push({id: deal.id, lastName: deal.attributes.owner_last_name}));
        this.setState({
          deals: deals
        })
      }
    }
      // Customizable Area End
    }

    // Customizable Area Start

    showMoreHandler = () => {
      this.setState({
        nbrOfDeals: this.state.nbrOfDeals + 4
      })
    }
    navigateToDealDashboard = (dealId: string) => {
      localStorage.setItem('createdDealId', dealId);
      window.location.href = "/ProjectTemplatesDealDashboard";
    }

    getDeals=async()=>{
      let token = await getStorageData("authToken");
      const header = {"token": token};
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getDealsCallIdFull = message.messageId;
      message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_posts/deals/search_deals?search=' + this.state.searchTerm)
        message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header))
        message.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod);
      runEngine.sendMessage(message.id, 
        message
      );
    }
    async componentDidMount() {
        super.componentDidMount();
        this.getContactInfo()
        this.getDealsCount()
        this.getLatestDeal()
        this.getDeals()
        if (window.location.href.includes('/Dashboard'))
          {
            this.setState({
              isActiveTab : 0,
            })
          }
        else if (window.location.href.includes('/CreateDealMain') || window.location.href.includes('/AllDeals'))
          {
            this.setState({
              isActiveTab : 1,
            })
          }
          else if (window.location.href.includes('/MyTeam'))
            {
              this.setState({
                isActiveTab : 2,
              })
            }
    }

    setLoading = () => {
      this.setState({
        loading : false,
      })
    }

    isOpen = () => {
      if (this.state.isOpen === true) 
        {
          this.setState({isExpanded : false})
        }
      this.setState({
        isOpen : !this.state.isOpen,
      })
    }

    toggleExpand = () => {
      if (this.state.isOpen) this.setState(prevState => ({isExpanded : !prevState.isExpanded}))
    }

    searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ searchTerm: event.target.value, nbrOfDeals: 4 });
    };

    activeTab = (num : number) => {
      if (this.state.isActiveTab != num)
        {
        this.setState({
          isActiveTab : num,
        })
        if (num === 0) this.navigateToDashboard()
        else if (num === 1) {
          if (this.state.role === 'dealer' || this.state.role === 'dealer_admin') 
            {
              this.state.dealsCount == 0 ? this.navigateToCreateDealMain() : this.navigateToAllDeals()
              console.log("rolerole")
            }
          else this.navigateToAllDeals()
        }
        else if (num == 2) this.navigateToMyTeam()
      }
    }

    navigateToDashboard =()=>{
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "Dashboard");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

    navigateToCreateDealMain =()=>{
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "CreateDealMain");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

    navigateToAllDeals =()=>{
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "AllDeals");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

    navigateToMyTeam = () => {
      const inviteUserTxtData = new Message( getName( MessageEnum.NavigationMessage ));
      inviteUserTxtData.addData(getName( MessageEnum.NavigationTargetMessage ), 
        "MyTeam");
      inviteUserTxtData.addData(getName( MessageEnum.NavigationPropsMessage ), this.props);
      this.send( inviteUserTxtData );
    }

      getContactInfo = async () => {
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token" : tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getContactInfoCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getContactInfoAPIEndpoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod);
        runEngine.sendMessage(
          WebrequestMessage.id, 
          WebrequestMessage
        );
      }

      getDealsCount = async () => {
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token" : tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDealsCountCallId = 
        WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.dealsCountGetEndPoint);
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(Webheader));
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod);
        runEngine.sendMessage(
          WebrequestMessage.id, 
          WebrequestMessage
        );
      }

      getLatestDeal=async()=>{
        let tokenn = await getStorageData("authToken");
        const Webheader = {"token": tokenn};
        const WebrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getLatestDealsCallId = WebrequestMessage.messageId;
        WebrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.latestDealsGetEndPoint)
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(Webheader))
        WebrequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod);
        runEngine.sendMessage(WebrequestMessage.id, 
          WebrequestMessage
        );
      }
    // Customizable Area End
  }