import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Backdrop,
  CircularProgress,
  SvgIcon,
  Tooltip,
  styled,
  LinearProgress
} from "@material-ui/core";
import ProjectTemplatesDocumentsUploadController, {
  Props,
  configJSON,
  formattedDate,
} from "./ProjectTemplatesDocumentsUploadController";
import Dropzone from "react-dropzone";
import { uploadIcon, viewUploadIcon, downloadIcon, compareDocArrow, downloadComplete, errorOutline, downloadProgress } from "./assets";
import { CheckCircleOutline, DeleteOutline, Edit, MoreVert, SearchOutlined, Delete, Clear, MoreHoriz, CloudDownload, Create, ErrorOutline } from "@material-ui/icons";
import ClearIcon from '@material-ui/icons/Clear';
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import DocumentMenu, { MenuAction } from "../../../components/src/CustomMenu";
import ProjectTemplatesDocumentsCompare from "./ProjectTemplatesDocumentsCompare.web";
import moment from "moment";
import SendToNotes from "../../chat/src/SendToNotes.web";
import IssueRaised from "./IssueRaised.web";
import AddSharpIcon from '@material-ui/icons/AddSharp';
import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';

const styles = createStyles({
  documentUploadDialog: {
    "& > div:nth-child(3) > div:nth-child(1)": {
      maxHeight: "500px",
      width: "600px",
      borderRadius: "8px",
      background: "#FFFFFF",
      boxShadow: "none",
      margin: 0,
      display: "block",
      flex: "none",
    },
  },

  documentUploadDialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 16px 12px 0px",
  },

  closeIcon: {
    width: "32px",
    height: "32px",
  },

  documentUploadDialogTitle: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    color: "rgba(1, 61, 79, 1)",
    letterSpacing: "-0.005em",
    [`@media (min-width:1920px)`]: {
      fontSize: 24,
    },
  },

  editDocumentDialogTitle: {
    padding: 0,
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    textAlign: "left",
    color: "#0F172A",
    [`@media (min-width:1920px)`]: {
      fontSize: 24,
    },
  },

  documentUploadDialogContent: {
    padding: "0px 44.5px",
  },

  editDocumentDialogContentText: {
    fontFamily: "Gotham",
    fontWeight: 300,
    fontSize: "15px",
    lineHeight: "26px",
    color: '#013D4F',
    marginTop: '16px',
    marginBottom: '32px',
    [`@media (min-width:1920px)`]: {
      fontSize: 18,
    },
  },

  browseFiles: {
    marginTop: "26px",
    marginBottom: "32px",
    gap: "8px",
    borderRadius: "8px",
    background: "#FFFFFF",
    border: "1px solid #C5CBC9",
    display: "flex",
    justifyContent: "center",
    padding: "11px 24px",
    cursor: "pointer",
  },
  documentListItem: {
    marginBottom: "1rem"
  },

  documentUploadDialogActions: {
    textTransform: "initial",
    padding: "24px 35px",
    borderTop: "1px solid rgba(242, 243, 242, 1)",

    "& > button": {
      gap: "8px",
      borderRadius: "8px",
      height: 44,
      minWidth: '125px',
      padding: '16px 53px',
      [`@media (min-width:1920px)`]: {
        height: 56
      },
    },

    "& > button > span": {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      textAlign: "left",
      textTransform: "initial",
      [`@media (min-width:1920px)`]: {
        fontSize: 16
      },
    },
  },
  cancelBtn: {
    border: "1px solid rgba(76, 77, 76, 1)",

    "& > span": {
      color: "rgba(76, 77, 76, 1)",
    },
  },

  submitBtn: {
    background: "#4FC8EC",
    "&:hover": {
      background: "#4FC8EC",
    },

    "&.Mui-disabled": {
      background: "#E8F8FD",
      "& > span": {
        color: "#A3E2F5",
      },
    },

    "& > span": {
      color: "#FFFFFF",
    },
  },

  backDrop: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },

  orStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    [`@media (min-width:1920px)`]: {
      fontSize: 14
    },
  },
  uploadIcon: {
    height: '24px', width: '24px',
    [`@media (min-width:1920px)`]: {
      height: '40px', width: '40px'
    },
  },

  dragAndDrop: {
    marginTop: "8px",
    fontFamily: "Gotham, sans-serif",
    fontSize: "18px",
    fontWeight: 300,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "rgba(1, 61, 79, 1)",
    [`@media (min-width:1920px)`]: {
      fontSize: 24
    },
  },

  allowableStatement: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
    textAlign: "left",
    color: "rgba(75, 76, 75, 1)",
    [`@media (min-width:1920px)`]: {
      fontSize: 12
    },
  },

  selectedFiles: {
    padding: 0,
    listStyle: "none",
    maxHeight: "200px",
  },
  uploadDocumentSection: {
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  uploadTitle: {
    color: "#C5CBC9",
    fontWeight: 300,
    fontSize: screen.width <= 1440 ? "16px" : "20px",
    lineHeight: "18px",
    fontFamily: "Gotham, sans-serif",
    textAlign: "center",
  },

  documentsSection: {
    marginTop: "25px",
    marginBottom: "174px",
    padding: "40px 30px 35px 30px",
    gap: "30px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px 0px #8989895C",
  },

  documentSectionContent: {
    marginTop: "30px",
    display: "flex",
    gap: "20px",
  },
  readyBox: {
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    padding: "35px 30px",
    marginBottom: "30px",

    "& p": {
      fontFamily: "Gotham",
      fontSize: screen.width <= 1440 ? "18px" : "24px",
      fontWeight: 400,
      lineHeight: "37px",
      textAlign: "left",
      color: "rgba(1, 61, 79, 1)",
    },

    "& button": {
      width: "100%",
      borderRadius: "8px",
      padding: "16px 0px",
    },
  },
  readyButton: {
    background: "rgba(234, 251, 250, 1)",
    color: "rgba(38, 187, 179, 1)",
    textTransform: "initial",
    "&:hover": {
      background: "rgba(234, 251, 250, 1)",
      color: "rgba(38, 187, 179, 1)",
    },
  },

  notReadyButton: {
    background: "rgba(254, 242, 242, 1)",
    color: "rgba(196, 57, 55, 1)",
    textTransform: "initial",
    "&:hover": {
      background: "rgba(254, 242, 242, 1)",
      color: "rgba(196, 57, 55, 1)",
    },
  },
  basicInfoTypo: {
    fontSize: screen.width <= 1440 ? "12px" : "18px",
    display: 'flex',
    gap: 10,
    lineHeight: "18px",
    whiteSpace: 'nowrap'
  },

  basicInfoValue: {
    fontSize: screen.width <= 1440 ? "14px" : "20px",
    fontWeight: 500,
    marginLeft: "10px",
    color: "#4B4C4B",
  },

  boxInfoText: {
    fontSize: 15,
    color: "#4B4C4B",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: 500,
    fontFamily: "Roboto",
  },

  files: {
    display: "flex",
    gap: "15px",
    overflow: "auto",
    width: "100%",
    padding: "10px 0px",
  },

  fileBox: {
    padding: "0px 0px 0px 15px",
    borderRadius: "5px",
    border: "1px solid rgba(228, 231, 230, 1)",
    color: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  fileName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    color: "rgba(75, 76, 75, 1)",
    whiteSpace: "nowrap",
  },

  selectedFileName: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "12px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "rgba(75, 76, 75, 1)",
  },

  selectedFileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },

  circularProgress: {
    height: "16px",
    width: "16px",
    marginRight: "10px",
  },

  pdfIframe: {
    height: "100%",
    width: '100%',
    borderRadius: "5px",
    background: "rgba(237, 245, 247, 1)",
  }, zoomButtons: {
    color: "#fff",
    width: '20px',
    height: '20px',
    [`@media (min-width:1920px)`]: {
      width: '1em',
      height: '1em',
    },
  },
  zoomText:{
    fontSize:'16px',
    fontFamily:'roboto',
    fontWeight:400,
    color:'#fff',
    [`@media (min-width:1920px)`]: {
     fontSize:'20px'
    },
  },

  documentsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  documentsToolBar: {
    display: "flex",
    "& .MuiInput-underline:before": {
      content: "none",
    },
    alignItems: "center",
    gap: "20px",
  },
  uploadMoreContainer: {
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    alignItems: 'center'
  },

  readyButtonChecked: {
    background: "#26BBB3",
    color: "white",
    "&:hover": {
      background: "#26BBB3",
      color: "white",
    },
  },
  notReadyButtonChecked: {
    background: "#C43937",
    color: "white",
    "&:hover": {
      background: "#C43937",
      color: "white",
    },
  },
  checkcircleoutline: {
    marginRight: "8px",
  },
  errorOutline: {
    marginRight: "8px",
  },
  feeInput: {
    border: "0px",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    color: "#1492B8",
    textAlign: "right",
    display: "inline-block",
    "&:focus": {
      border: "0px",
      outline: "0px",
    },
  },

  truncate: {
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis",
    maxWidth: "300px",
    display: "inline-block",
  },

  disabled: {
    background: '#F1F4F3'
  },

  textLabel: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    color: "#C5CBC9"
  }
});

export const StyledBasicBox = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "174px",
  padding: "25px 20px",
  gap: "30px",
  borderRadius: "10px",
  background: "#FFFFFF",
  boxShadow: "0px 0px 4px 0px #8989895C",
  [theme.breakpoints.up(1920)]: {
    padding: "40px 30px 35px 30px",
  },
  '& .headingBasic': {
    fontFamily: "Gotham, sans-serif",
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 400,
    color: "#013D4F",
    [`@media (max-width:1920)`]: {
      fontSize: 24,
    },

  },
  '& .informationList': {
    display: 'flex',
    flexDirection: 'column',
    gap: "10px"
  },
  "& .basicInfoTypo": {
    [theme.breakpoints.up(1920)]: {
      fontSize: "18px",
      lineHeight: "22px",
    },
    color: '#939F9B',
    fontSize: "14px",
    lineHeight: "25px",
    whiteSpace: 'nowrap'
  },

  "& .basicInfoValue": {
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
      lineHeight: "40px",
    },
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "10px",
    color: "#4B4C4B",
  },
  "& .uploadMore": {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#4FC8EC",
    [theme.breakpoints.up(1920)]: {
      fontSize: "16px",
      lineHeight: "22px",


    }
  },
  "& .uploadIcon": {
    width: 16,
    height: 16,
    color: "#4FC8EC",
    [theme.breakpoints.up(1920)]: {
      width: 20,
      height: 20


    }
  }, "& .fileText": {
    marginTop: "8px",
    fontFamily: "Gotham, sans-serif",
    fontSize: "18px",
    [theme.breakpoints.up(1920)]: {
      fontSize: "24px",

    },
    fontWeight: 300,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "rgba(1, 61, 79, 1)",
  },
  "& .fileUploadLogo": {
    width: 25,
    height: 25,
    color: "#4FC8EC",
    [theme.breakpoints.up(1920)]: {
      width: 40,
      height: 40
    },
  },
  "& .basicInformation": {
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    backgrounf: "#fff",
    padding: "25px 20px",
    height: '695px',
    overflowY: 'auto',
    [theme.breakpoints.up(1920)]: {
      padding: '35px 30px',
      height: "943px"
    },
  },
  "& .selectedFileBasicInformation": {
    maxHeight: "395px",
    overflowY: "auto",
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    backgrounf: "#fff",
    padding: "25px 20px",
    [theme.breakpoints.up(1920)]: {
      height: '643px',
      padding: '35px 30px'
    },
  },
  "& .readyBox": {
    borderRadius: "10px",
    border: "1px solid #E5E6E5",
    padding: "25px 20px",
    maxHeight: '266px',
    display: 'flex',
    flexDirection: "column",
    gap: '10px',
    "& button": {
      width: "100%",
      borderRadius: "8px",
      height: 44,
      [theme.breakpoints.up(1920)]: {
        height: 56
      },
    },
    [theme.breakpoints.up(1920)]: {
      padding: '35px 30px',
      height: '343px'
    },
  },
  "& .compareDocBtn": {
    borderRadius: "8px",
    border: "1px solid #013D4F",
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    height: "33px"
  },
  "& .readyBoxFileName": {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    fontSize: 18,
    [theme.breakpoints.up(1920)]: {
      fontSize: 24
    },
  },
  "& .readyBoxHeader": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
    alignItems: "center",
    gap: "8px",
  },
  "& .rightSideSection": {
    width: "30%",
    height: "695px",
    display: 'flex',
    flexDirection: 'column',
    gap: "20px",
    [theme.breakpoints.up(1920)]: {
      gap: "30px",
      height: "943px",

    },
  },
  '& .leftSideSection': {
    width: "70%",
    borderRadius: "20px",
    border: "1px solid #F1F4F3",
    cursor: "pointer",
    height: "695px",
    overflow: 'hidden',
    [theme.breakpoints.up(1920)]: {
      gap: "30px",
      height: "943px",
    },
  },
}));
// Customizable Area End

export class ProjectTemplatesDocumentsUpload extends ProjectTemplatesDocumentsUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  documentMenu = [
    {
      id: uuidv4(),
      name: "Rename",
      value: MenuAction.Rename,
      icon: Edit,
      dataTestID: "rename-btn",
    },

    {
      id: uuidv4(),
      name: "Delete",
      value: MenuAction.Delete,
      icon: DeleteOutline,
      dataTestID: "delete-btn",
    },
  ];
  documentMenuIssueRaised = [
    {
      id: uuidv4(),
      name: "Rename",
      value: MenuAction.Rename,
      icon: Edit,
      dataTestID: "rename-btn",
    },

    {
      id: uuidv4(),
      name: "Delete",
      value: MenuAction.Delete,
      icon: DeleteOutline,
      dataTestID: "delete-btn",
    },
    {
      id: uuidv4(),
      name: "Reupload",
      value: "Reupload",
      icon: uploadIcon,
      dataTestID: "reupload-btn",
    },
  ];
  menu = [
    {
      id: uuidv4(),
      name: "Download All Documents",
      value: MenuAction.DownloadAllDocuments,
      icon: CloudDownload,
      dataTestID: "download-all-documents-btn",
    },
    {
      id: uuidv4(),
      name: "Edit Documents",
      value: MenuAction.EditDocuments,
      icon: Create,
      dataTestID: "edit-documents-btn",
    },
  ];
  renderDocumentButtonsSPHiredSPSide = () => {
    const { classes } = this.props;
    const { selectedFile } = this.state;
    return (
      <>
        <Button
          data-test-id="approve-btn"
          onClick={() => {
            this.changeDocumentStatus('approved', selectedFile.id);
            this.changeSelectedDocumentStatus('approved');
          }}
          className={clsx(classes.readyButton, {
            [classes.readyButtonChecked]:
              selectedFile.status === "approved",
          })}
        >
          {selectedFile.status === "approved" && (
            <CheckCircleOutline
              className={classes.checkcircleoutline}
            />
          )}
          {selectedFile.status === "approved" ? 'Approved' : 'Approve'}
        </Button>
        <Button
          data-test-id="raise-an-issue-btn"
          onClick={() => {
            this.openRaiseIssue(selectedFile.status)
          }}
          className={clsx(classes.notReadyButton, {
            [classes.notReadyButtonChecked]:
              selectedFile.status === "issue_raised",
          })}
        >
          {selectedFile.status === "issue_raised" && (
            <ErrorOutline
              className={classes.errorOutline}
            />
          )}
          {selectedFile.status === "issue_raised" ? 'Issue Raised' : 'Raise an issue'}
        </Button>
      </>
    )
  }

  renderDocumentButtonsSPHiredDealerSide = () => {
    const { classes } = this.props;
    const { selectedFile, issueRaised } = this.state;
    if (selectedFile.status === "issue_raised") {
      return (
        <>
          <Button
            data-test-id="raise-an-issue-btn"
            onClick={() => {
              this.setState({
                showIssueRaised: true
              })
            }}
            className={clsx(classes.notReadyButton, classes.notReadyButtonChecked)}
          >
            <ErrorOutline
              className={classes.errorOutline}
            />
            Issue Raised
          </Button>
          <Typography className={classes.textLabel}>Issue raised by service provider on {formattedDate(issueRaised.issue_raised_at)}</Typography>
        </>
      )
    } else if (selectedFile.status === "approved") {
      return (
        <>
          <Button
            data-test-id="approve-btn"
            onClick={() => { }}
            className={clsx(classes.readyButton, classes.readyButtonChecked)}
          >
            <CheckCircleOutline
              className={classes.checkcircleoutline}
            />
            Approved
          </Button>
          <Typography className={classes.textLabel}>Approved by service provider on 5/2/2024</Typography>
        </>
      )
    } else return (
      <Tooltip title="After hiring a service provider, only the service provider can change document status." placement="top">
        <span style={{ display: 'flex', gap: "10px", flexDirection: 'column' }}>
          <Button
            data-test-id="approve-btn"
            onClick={() => {
              this.changeDocumentStatus('approved', selectedFile.id);
              this.changeSelectedDocumentStatus('approved');
            }}
            disabled={true}
            className={clsx(classes.readyButton, classes.disabled, {
              [classes.readyButtonChecked]:
                selectedFile.status === "approved",

            })}
          >
            {selectedFile.status === "approved" && (
              <CheckCircleOutline
                className={classes.checkcircleoutline}
              />
            )}
            {selectedFile.status === "approved" ? 'Approved' : 'Approve'}
          </Button>
          <Button
            data-test-id="raise-an-issue-btn"
            disabled={true}
            onClick={() => {
              this.openRaiseIssue()
            }}
            className={clsx(classes.notReadyButton, classes.disabled, {
              [classes.notReadyButtonChecked]:
                selectedFile.status === "issue_raised",
            })}
          >
            {selectedFile.status === "issue_raised" && (
              <ErrorOutline
                className={classes.errorOutline}
              />
            )}
            {selectedFile.status === "issue_raised" ? 'Issue Raised' : 'Raise an issue'}
          </Button>
        </span>
      </Tooltip>
    )
  }

  renderDocumentButtonsSPHired = () => {
    const { user } = this.props;
    const isDealer = user.role.includes('dealer');
    if (!isDealer) {
      return this.renderDocumentButtonsSPHiredSPSide();
    } else {
      return this.renderDocumentButtonsSPHiredDealerSide();
    }
  }

  renderDocumentsButtonsSPNotHired = () => {
    const { classes } = this.props;
    const { selectedFile } = this.state;
    return (
      <>
        <Button
          data-test-id="ready-btn"
          onClick={() => {
            this.changeDocumentStatus('ready', selectedFile.id);
            this.changeSelectedDocumentStatus('ready');
          }}
          className={clsx(classes.readyButton, {
            [classes.readyButtonChecked]:
              selectedFile.status === "ready",
          })}
        >
          {selectedFile.status === "ready" && (
            <CheckCircleOutline
              className={classes.checkcircleoutline}
            />
          )}
          Ready
        </Button>
        <Button
          data-test-id="not-ready-btn"
          onClick={() => {
            this.changeDocumentStatus('not_ready', selectedFile.id);
            this.changeSelectedDocumentStatus('not_ready');
          }}
          className={clsx(classes.notReadyButton, {
            [classes.notReadyButtonChecked]:
              selectedFile.status === "not_ready",
          })}
        >
          {selectedFile.status === "not_ready" && (
            <ErrorOutline
              className={classes.errorOutline}
            />
          )}
          Not Ready
        </Button>
      </>
    )
  }

  renderDocumentButtons = () => {
    const { isSPHired } = this.props;
    if (isSPHired) {
      return this.renderDocumentButtonsSPHired();
    } else {
      return this.renderDocumentsButtonsSPNotHired();
    }
  }

  renderDialog = () => {
    const { classes } = this.props;
    const {
      selectedFiles,
      isUploadingDocuments,
    } = this.state;

    const uploadedCount = Object.values(this.state.uploadProgress).filter((p) => p === 100).length;

    const PinkLinearProgress = withStyles({
      bar: {
        backgroundColor: "#013D4F",
        height: "6px"
      },
      root: {
        backgroundColor: "#DEE4E2",
        height: "6px"
      },
    })(LinearProgress);

    return (
      <Dialog
        data-test-id="documents-upload-dialog"
        open={isUploadingDocuments}
        keepMounted
        onClose={() => {
          this.handleStopUploadingDocuments();
        }}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.documentUploadDialog}
      >
        <Box
          className={classes.documentUploadDialogHeader}
          data-test-id="documents-upload-dialog-header"
        >
          <Clear
            className={classes.closeIcon}
            data-test-id="close-btn"
            onClick={() => {
              this.handleStopUploadingDocuments();
            }}
          />
        </Box>
        <DialogContent
          className={classes.documentUploadDialogContent}
          data-test-id="documents-upload-dialog-content"
        >
          <Typography className={classes.documentUploadDialogTitle}>
            Upload your documents
          </Typography>

          <Dropzone
            data-test-id="files-dropzone"
            multiple={true}
            onDrop={(selectedFiles) =>
              this.handleAddSelectedFiles(selectedFiles)
            }
            accept={{
              "application/pdf": [],
              "image/png": [],
              "image/jpeg": [],
              "text/plain": [],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                {selectedFiles.length === 0 && <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Box className={classes.browseFiles}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img className={classes.uploadIcon} src={uploadIcon}></img>
                      <Box sx={{ height: "77px", textAlign: "center" }}>
                        <Typography className={classes.dragAndDrop}>
                          Drag & Drop
                        </Typography>
                        <p className={classes.orStatement}>
                          or click{" "}
                          <span style={{ color: "#4FC8EC" }}> here </span> to
                          browse your files
                        </p>
                      </Box>
                      <p className={classes.allowableStatement}>
                        {configJSON.allowableStatement}
                      </p>
                    </Box>
                  </Box>
                </div>}
                {selectedFiles.length > 0 && (
                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      style={{ fontSize: "16px", marginTop: "2rem" }}
                      className={classes.documentUploadDialogTitle}
                    >
                      {uploadedCount} out of {selectedFiles.length} files uploaded
                    </Typography>
                    <ul
                      className={classes.selectedFiles}
                      style={{ overflowY: 'auto', paddingRight: "1rem" }}
                      data-test-id="selected-files"
                    >
                      {selectedFiles.map((file, index) => (
                        <li key={index} className={classes.documentListItem}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography>{file.name}</Typography>
                                <Typography>{String(this.state.uploadProgress[file.name])}%</Typography>
                              </Box>
                              <Box width="100%">
                                <PinkLinearProgress
                                  variant="determinate"
                                  value={this.state.uploadProgress[file.name] || 0}
                                  style={{ marginTop: "5px" }}
                                />
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => this.handleDeleteFile(file)}
                                aria-label="delete"
                                size="small"
                                style={{ marginLeft: "2rem" }}
                                data-test-id="icon-btn"
                              >
                                {this.state.uploadProgress[file.name] === 100 ? <Delete fontSize="small" /> : <ClearIcon />}
                              </IconButton>
                            </Box>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </Box>
                )}
              </section>
            )}
          </Dropzone>
        </DialogContent>

        <DialogActions
          className={classes.documentUploadDialogActions}
          data-test-id="documents-upload-dialog-actions"
        >
          <Button
            data-test-id="cancel-btn"
            className={classes.cancelBtn}
            onClick={() => {
              this.handleStopUploadingDocuments();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedFiles.length == 0}
            data-test-id="submit-btn"
            className={classes.submitBtn}
            onClick={() => {
              this.handleStopUploadingDocuments();
              this.uploadFiles(selectedFiles);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  renderDocuments = () => {
    const { classes, isDealCompletedOrDeleted } = this.props;
    const {
      filteredDocuments,
      edittingDocument,
      selectedStatus,
      searchKey,
    } = this.state;
    let displayDocuments = filteredDocuments.filter(file => file.filename.includes(searchKey));
    if (selectedStatus !== 'all') {
      displayDocuments = displayDocuments.filter(file => file.status === selectedStatus);
    }
    return displayDocuments.length > 0 &&
      <Box className={classes.files} data-test-id="document-files">
        <>
          {displayDocuments.map((file, index) => (
            <Box
              className={classes.fileBox}
              key={index}
              onClick={() => this.handleSelectFile(file)}
              data-test-id="documnetSelect"
            >
              <img
                src={this.fileIcon(file)}
                className={classes.circularProgress}
              ></img>
              {edittingDocument.id === file.id ? (
                <input
                  // className={classes.feeInput}
                  value={edittingDocument.filename}
                  onChange={this.renameFile}
                  onKeyDown={this.renameFile}
                  data-test-id="rename-document"
                />
              ) : (
                <Typography className={classes.fileName}>
                  {file.filename}
                </Typography>
              )}
              <DocumentMenu
                isDealCompletedOrDeleted={isDealCompletedOrDeleted}
                data-test-id="document-menu"
                onSelectAction={(value) => {
                  this.selectDocumentAction(value, file);
                }}
                menu={file.status === 'issue_raised' ? this.documentMenuIssueRaised : this.documentMenu}
                icon={<MoreVert />}
              />
            </Box>
          ))}
        </>
      </Box>
  }
  renderHeader = () => {
    const { classes, basicInfos, documents, isDealCompletedOrDeleted, isSPHired } = this.props;
    const {
      selectedFile,
      selectedStatus,
    } = this.state;
    const ToolTip = withStyles({
      tooltip: {
        padding: '16px',
        radius: '8px',
        background: '#FFFFFF',
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        color: '#4B4C4B',
        fontSize: '15px'
      },
    })(Tooltip);
    const customArrowIcon = () => {
      return (
        <SvgIcon style={{ width: "20px", height: "20px" }}>
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
        </SvgIcon>
      );
    };
    const CustomSelect = styled(Select)({
      '& .MuiSelect-select': {
        display: 'flex',
        alignItems: 'center'
      },
    })
    return (
      <StyledBasicBox>
        <Box className={classes.documentsHeader}>
          <Typography variant="h1">
            Documents
            <span style={{ color: "#C5CBC9" }}>
              {" "}
              ({documents ? documents.length : 0})
            </span>
          </Typography>
          {documents.length > 0 && (
            <Box className={classes.documentsToolBar}>
              <Input
                onChange={this.handleFilterDocuments}
                placeholder="Search documents..."
                data-test-id="search-documents"
                id="standard-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                }
              />
              <CustomSelect
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedStatus}
                onChange={(e) => { this.setState({ selectedStatus: e.target.value as string }) }}
                IconComponent={customArrowIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'processing'}><img
                  src={downloadProgress}
                  className={classes.circularProgress}
                ></img>Processing</MenuItem>
                {!isSPHired && <MenuItem value={'ready'}><img
                  src={downloadComplete}
                  className={classes.circularProgress}
                ></img>Ready</MenuItem>}
                {!isSPHired && <MenuItem value={'not_ready'}><img
                  src={errorOutline}
                  className={classes.circularProgress}
                ></img>Not Ready</MenuItem>}
                {isSPHired && <MenuItem value={'approved'}><img
                  src={downloadComplete}
                  className={classes.circularProgress}
                ></img>Approved</MenuItem>}
                {isSPHired && <MenuItem value={'issue_raised'}><img
                  src={errorOutline}
                  className={classes.circularProgress}
                ></img>Issue Raised</MenuItem>}
              </CustomSelect>
              <Box
                className={classes.uploadMoreContainer}
                onClick={this.handleUploadingDocuments}
              >
                <img src={viewUploadIcon}
                  className={'uploadIcon'}

                />
                <Typography className={"uploadMore"}>
                  Upload Your Documents
                </Typography>
              </Box>
              <DocumentMenu
                isDealCompletedOrDeleted={isDealCompletedOrDeleted}
                data-test-id="documents-menu"
                onSelectAction={this.handleDownloadAllDocuments}
                menu={this.menu}
                icon={<MoreHoriz style={{ transform: 'rotate(90deg' }} />}
                totalEditLeft={this.state.remainingEditable}
                totalEdit={2}
              />
            </Box>
          )}
        </Box>
        {this.renderDocuments()}
        <Box className={classes.documentSectionContent}>
          <Box className={"leftSideSection"}>
            {"filename" in selectedFile ? (
              <>
                {selectedFile.filename.includes(".pdf") ?
                  <iframe
                    className={classes.pdfIframe}
                    src={selectedFile.url}
                  /> :
                  <>
                    <Box style={{ height: '44px', backgroundColor: 'black', display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
                      <IconButton data-test-id="zoom_out" onClick={this.handleZoomOut} className="zoomButton"><RemoveSharpIcon
                        className={classes.zoomButtons}
                      /></IconButton>
                      <Typography component={'h6'} className={classes.zoomText} data-test-id='zoom_text'>{`${this.state.zoom}%`}</Typography>
                      <IconButton data-test-id="zoom_in" onClick={this.handleZoomIn}><AddSharpIcon
                        className={classes.zoomButtons}
                      /></IconButton>
                    </Box>
                    <img className={classes.pdfIframe}
                      style={{ width: `${this.state.zoom}%`, transition: "0.3s ease-in-out" }}
                      src={selectedFile.url} />
                  </>
                }
              </>

            ) : (
              <Box
                onClick={this.handleUploadingDocuments}
                data-test-id="documents-upload-section"
                className={classes.uploadDocumentSection}
              >
                <img className="fileUploadLogo" src={uploadIcon}></img>
                <Box sx={{ height: "77px" }}>
                  <Typography variant="h2" className={'fileText'}>
                    Click here to attach your files
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={"rightSideSection"}>
            {"filename" in selectedFile && (
              <Box className={"readyBox"}>
                <Box className={"readyBoxHeader"}>
                  <Typography className={"readyBoxFileName"}>
                    {selectedFile.filename}
                  </Typography>
                  <a href={selectedFile.url} download={true} target="_blank"><img width="24px" height="24px" src={downloadIcon} /></a>
                </Box>
                <Box display={'flex'} flexDirection={'column'} style={{ gap: "10px" }} >
                  {this.renderDocumentButtons()}
                </Box>
                <Button
                  data-test-id="compare-doc-btn"
                  onClick={this.triggerCompareDoc}
                  className={"compareDocBtn"}
                >
                  <Typography style={{ textTransform: 'initial' }} variant="h5">Compare Document</Typography>
                  <img src={compareDocArrow}></img>
                </Button>
              </Box>
            )}
            <Box className={"filename" in selectedFile ? "selectedFileBasicInformation" : "basicInformation"}>
              <Typography className="headingBasic">Basic Information</Typography>
              <Box className="informationList" data-test-id="basic-infos">
                {Object.entries(basicInfos).map(([key, value]) => (
                  <Typography
                    variant="body1"
                    className={"basicInfoTypo"}
                    key={key}
                  >
                    {key}{" "}
                    {key === "OWNER'S NAME" ? <ToolTip title={value} placement="top-end">
                      <Typography variant="body2" className={`${classes.truncate} ${classes.basicInfoValue}`}>{value}</Typography>
                    </ToolTip> : <span className={"basicInfoValue"}>{key === "DATE OF SALE" ? moment(value).format("MM.DD.YYYY") : value}</span>}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledBasicBox>
    )
  }

  renderEditDocumentsConfirmDialog = () => {
    const { isEditDocuments, remainingEditable } = this.state;
    const { classes } = this.props;
    return (
      <Dialog
        data-test-id="edit-documents-dialog"
        open={isEditDocuments}
        keepMounted
        onClose={() => { this.setState({ isEditDocuments: false }) }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.documentUploadDialog}
      >
        <Box className={classes.documentUploadDialogHeader}>
          <ClearIcon
            data-test-id="close-icon"
            onClick={() => { this.setState({ isEditDocuments: false }) }}
          />
        </Box>
        <DialogContent className={classes.documentUploadDialogContent}>
          <Typography className={classes.editDocumentDialogTitle}>
            Important Notice
          </Typography>
          <Box className={classes.editDocumentDialogContentText}>
            {`Are you sure you want to edit the information provided to the forms? You can edit the forms 2 times (this is ${remainingEditable} of 2).`}
          </Box>
        </DialogContent>
        <DialogActions className={classes.documentUploadDialogActions}>
          <Button
            data-test-id="cancel-btn"
            className={classes.cancelBtn}
            onClick={() => { this.setState({ isEditDocuments: false }) }}
          >
            Cancel
          </Button>
          <Button
            data-test-id="continue-btn"
            className={classes.submitBtn}
            onClick={this.handleEditDocuments}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { basicInfos, isSPHired, user } = this.props;
    const {
      selectedFile,
      filteredDocuments,
      showProgress,
      compareDocMode
    } = this.state;
    return (
      <>
        {this.renderHeader()}
        {this.renderDialog()}
        <ProjectTemplatesDocumentsCompare
          id="" navigation="" compareDocMode={compareDocMode}
          triggerCompareDoc={this.triggerCompareDoc} basicInfos={basicInfos}
          selectedFile={selectedFile} changeSelectedDocumentStatus={this.changeSelectedDocumentStatus} files={filteredDocuments}
          fileIcon={this.fileIcon}
          changeDocumentStatus={this.changeDocumentStatus}
          isSPHired={isSPHired}
          user={user}
        />
        <Backdrop style={{ color: "#fff", zIndex: 9999 }} open={showProgress}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {this.state.raiseIssue &&
          <SendToNotes
            reteriewDocumentStatus={this.reteriewDocumentStatus}
            accepted_selected_service_provider_id={this.props.accepted_selected_service_provider_id}
            closeNoteWindow={this.getWindowStatus}
            selectedFile={this.state.selectedFile}
            navigation={this.props.navigation} id={""} />
        }
        {this.state.showIssueRaised && <IssueRaised issueRaised={this.state.issueRaised} hideIssueRaised={() => this.setState({ showIssueRaised: false })} />}
        {this.renderEditDocumentsConfirmDialog()}
      </>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(ProjectTemplatesDocumentsUpload);
