import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { IChat } from "./ChatController";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChatData {
  map(arg0: () => JSX.Element): string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined;
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessageData[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessageData {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}
export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}

export interface RootChat {
  chat: Chat
  message: string
}

export interface Chat {
  id: number
  header_details: HeaderDetails
  messages: Message[]
}

export interface HeaderDetails {
  deal_id: number
  customer_last_name: string
  service_provider: string
}
export interface IMessageList {
  map(arg0: (message: IMessageList) => JSX.Element): unknown;
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  id: number
  message: string
  account_id: number
  chat_id: number
  created_at: string
  updated_at: string
  sender_details: SenderDetails
  attachments: Attachment[],
  edited: boolean;
  deleted: boolean;
  auto: boolean
}

export interface SenderDetails {
  sender_name: string
  sender_logo: string
  role: string
  dealership: string
  owner: boolean;
  service_provider: string
}
export interface Attachment {
  id: number
  file_name: string
  url: string
}






// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  chatBoxType: string
  closeWindow: (key: string) => void
  chatDetails?: IChat;
  role: string;
  cardStatus?: boolean
  retreviewReadMessages: () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatMessageData: Record<string, IMessageList[]>;
  chatData: IChatData | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  chatMessage: string;
  fileUpload: File | null
  chatfloatingWindowMinimize: boolean
  closeFloatingChat: boolean;
  headerDetails: HeaderDetails
  editMode: string
  editMessageId: string
  uploadProgress: number;
  isUploading: boolean;
  threeDotsId: string;
  addDocumentDialog: boolean,
  fileName: string;
  messageDetails: IMessageList
  showNewMessageButton: boolean;
  initialLoad: boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  deleteMessageApiCallId: string = ""
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  fileInputRef1: React.RefObject<HTMLInputElement>;
  chatContainerRef: React.RefObject<HTMLDivElement>;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      chatMessage: "",
      fileUpload: null,
      chatfloatingWindowMinimize: true,
      closeFloatingChat: true,
      headerDetails: {} as HeaderDetails,
      editMode: "",
      editMessageId: "",
      uploadProgress: 0,
      isUploading: false,
      threeDotsId: "",
      addDocumentDialog: false,
      fileName: "",
      messageDetails: {} as IMessageList,
      chatMessageData: {},
      showNewMessageButton: false,
      initialLoad: true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.fileInputRef1 = React.createRef();
    this.chatContainerRef = React.createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getChatDetails("", 0)
    this.updateReadMessages()
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.refreshChatInterval = setInterval(this.getChatDetails, 20000);


    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevProps.cardStatus !== this.props.cardStatus) {
      this.setState({ closeFloatingChat: false })
    }


    if (prevProps.chatDetails?.id !== this.props.chatDetails?.id) {
      this.getChatDetails("", 0)
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
      this.refreshChatInterval = setInterval(this.getChatDetails, 20000);
    }
   

    const prevMessages: IMessageList[] = Object.values(prevState.chatMessageData).flat();
    const currentMessages: IMessageList[] = Object.values(this.state.chatMessageData).flat();
    if (prevMessages.length === 0 && currentMessages.length > 0) {
      this.scrollToBottom();
      return; 
    }
    if (prevMessages.length > 0 && prevMessages.length < currentMessages.length) {
      const newMessage = currentMessages[currentMessages.length - 1]; // Get the latest message
      const isFromReceiver = newMessage.attributes.sender_details.role !== this.props.role; // Check sender

      if (isFromReceiver) {
        if (!this.state.initialLoad) {
          this.setState({ showNewMessageButton: true });
        }
      } else {
        this.scrollToBottom();
      }
      if (this.isUserAtBottom()) {
        this.scrollToBottom();
      }
    }
  }
  isUserAtBottom = () => {
    if (!this.chatContainerRef.current) return false;
    const { scrollHeight, clientHeight, scrollTop } = this.chatContainerRef.current;
    return scrollHeight - clientHeight <= scrollTop + 5;
  };
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && event.target.files) {
      const file = event.target.files[0] as Blob;
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.setState({ imageUrl: readerEvent.target?.result as string, docRes: file, isVisiblePreviewModal: true });
      };
      reader.readAsDataURL(file);
      this.setState({ docRes: file, isVisiblePreviewModal: true });
    }
    else {
      this.setState({ imageUrl: "", docRes: null })
    }
  };

  handleSendMessage = (type: string) => {
    this.sendChatMessage(type);
    this.setState({ editMode: "create" });
  };

  handleInsertImage = () => {
    const refrence = this.fileInputRef.current;
    if (refrence) {
      refrence.click();
    }
  };

  getChatDetails = async (toka: string, chatId: number) => {
    const { chatDetails } = this.props
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllChats}/${chatDetails?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = async (messageId: string, attachment_id: number) => {
    const { chatDetails } = this.props
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };
    const bodyData = {
      chat_id: chatDetails?.id,
      attachment_id: attachment_id,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserToChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addtoDocumentsEndPoint.replace(':id', messageId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  leaveChatRoom = (chatId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: parseInt(chatId + "", 10),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (type: string) => {
    const { chatDetails } = this.props
    const token = await getStorageData('authToken')
    const header = {
      token
    };
    const formData = new FormData();
    formData.append("message", this.state.chatMessage);
    formData.append("chat_id", chatDetails?.id || "")
    if (type != "edited") {
      if (this.state.fileUpload) {
        formData.append("[attachments][]", (this.state.fileUpload as Blob));
        this.setState({ docRes: null })
      }
    }

    let endPoint;
    let method;
    if (type != "edited") {
      endPoint = `${configJSON.createMessageEndPoint}`
      method = configJSON.postApiMethod


    } else {
      endPoint = `${configJSON.createMessageEndPoint}/${this.state.editMessageId}`
      method = configJSON.patchApiMethod

    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ chatMessage: "" }, () => {
      this.clearUploadFile()
    })
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = async () => {
    const token = await getStorageData('authToken')

    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.readMessagesEndPoint.replace(':chat_id', this.props.chatDetails?.id)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(this.state.chatId),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      if (token) this.setState({ token }, () => {

        this.getChatDetails(this.state.token, this.state.chatId)
      });
    } else if (apiRequestCallId === this.getChatApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.handleApiChatResponce(responseJson)

      }
    } else if (apiRequestCallId === this.deleteMessageApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.getChatDetails("", 0)

      }
    } else if (apiRequestCallId === this.sendMessageApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.setState({ chatMessage: "", editMode: 'create' }, () => {
          this.getChatDetails("", 0)
        })

      }
    } else if (apiRequestCallId === this.updateReadMessageApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.props.retreviewReadMessages()
      }
    } else if (apiRequestCallId === this.addUserToChatApiCallId) {
      if (responseJson && !responseJson.errors) {
        if (responseJson.message === "Document added for deal") {
          this.setState({ threeDotsId: "0", addDocumentDialog: false })
        }
      }
    }


    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (restApiDataCondition && apiRequestCallId === this.toggleMuteApiCallId) {
    }

    if (restApiDataCondition && apiRequestCallId === this.leaveChatApiCallId) {
      this.props.navigation.goBack();
    }
  }

  handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ chatMessage: event.target.value })
  }

  closeFloatingChat = () => {
    this.setState({ closeFloatingChat: false }, () => {
      this.props.closeWindow("key")
      clearInterval(this.refreshChatInterval as number);
    })
  }
  minimizeFloatingWindow = () => {
    this.setState({ chatfloatingWindowMinimize: false })
  }

  maximizeFloatingWindow = () => {
    this.setState({ chatfloatingWindowMinimize: true })
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setState({ fileUpload: file }, () => {
        this.handleUpload()
      })
    }
  };

  handleUpload = () => {
    if (!this.state.fileUpload) return;

    this.setState({ isUploading: true, uploadProgress: 0 });

    let progress = 0;
    const interval = setInterval(() => {
      progress += 5; // Simulate upload progress
      this.setState({ uploadProgress: progress });

      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 200); // Increase progress every 200ms
  };


  handleIconClick = () => {
    if (this.fileInputRef1.current) {
      this.fileInputRef1.current.click();
    }
  };
  clearUploadFile = () => {
    this.setState({ fileUpload: null, uploadProgress: 0, isUploading: false })
  }

  handleDeleteMesssage = async (message: IMessageList) => {
    const token = await getStorageData('authToken')

    const header = {
      token
    };
    let formData = new FormData();
    formData.append("chat_id", String(message.attributes.chat_id))
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createMessageEndPoint}/${message.attributes.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleApiChatResponce = (responseJson: RootChat) => {

    // const chatData = responseJson.chat.messages;
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const formatDate = (date: Date) => date.toISOString().split('T')[0];

    const categorizedMessages: Record<string, any[]> = {};

    responseJson.chat.messages.forEach((msg: any) => {
      const msgDate = new Date(msg.attributes.created_at);
      const msgDateString = formatDate(msgDate);

      let category = msgDateString === formatDate(today)
        ? 'Today'
        : msgDateString === formatDate(yesterday)
          ? 'Yesterday'
          : msgDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

      if (!categorizedMessages[category]) {
        categorizedMessages[category] = [];
      }
      categorizedMessages[category].push(msg);
    });
    this.setState({
      chatMessageData: categorizedMessages,
      headerDetails: responseJson.chat.header_details,
      initialLoad: false
    })
  }

  convertDate = (timestamp: string): string => {
    if (!timestamp) {
      return ''; // Return empty string if date is not available
    }

    const now = moment(); // Current time
    const time = moment(timestamp); // Convert timestamp to Moment object
    const diffInDays = now.diff(time, 'days');
    const diffInMonths = now.diff(time, 'months');
    const diffInYears = now.diff(time, 'years');
    if (time.isSame(now, 'day')) {
      return `Today, ${time.format('h:mm A')}`; // Format as "Today, 7:34 PM"
    } else if (time.isSame(now.clone().subtract(1, 'days'), 'day')) {
      return `Yesterday, ${time.format('h:mm A')}`; // Format as "Yesterday, 7:34 PM"
    } else if (diffInDays < 30) {
      return `${diffInDays} days ago`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths} months ago`;
    } else {
      return `${diffInYears} years ago`;
    }
  };

  handleEditMessage = (message: IMessageList) => {
    this.setState({ chatMessage: message.attributes.message, editMode: "edited", editMessageId: String(message.attributes.id) })

  }


  handleSendMessage1 = (type: string) => {
    if (type === 'edited') {
      this.handleSendMessage("edited")

    } else {
      this.handleSendMessage("create")
    }

  }
  handleDownload = (url: string, fileName: string) => {
    this.setState({ threeDotsId: "0" }, () => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch();
    })

  };

  openThreeDots = (messageId: string) => {
    this.setState({ threeDotsId: messageId })
  }
  closeThreeDots = () => {
    this.setState({ threeDotsId: "0" })
  }

  handleAddDocumentsDialog = (message: IMessageList) => {
    this.setState({
      addDocumentDialog: true,
      fileName: message.attributes.attachments[0].file_name,
      messageDetails: message
    })
  }
  handleCloseAddDocumentsDialog = () => {
    this.setState({
      addDocumentDialog: false
    })
  }

  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
      this.setState({ showNewMessageButton: false }, () => {
        this.updateReadMessages()
      });
    }
  };

  // Customizable Area End
}
