import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
interface Notification {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  avatarText: string;
  avatarColor?: string;
  isRead: boolean;
  ownerLastName?: string | null;
  notificationId:string | null;
  profile:string | null;
  clickable: boolean;
}

export interface GetUserResponseSuccess {
  data: Data;
}
export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name?: null;
  role: string;
  profile_picture?: null;
  group_created_on?: null;
  is_deal_pending: boolean;
  deal_data?: null;
  solo_dealership_id: number;
}

interface GetUserError {
  errors:[
    {
      token:string;
    }
  ]
}

interface ResponseMarkAsRead{
  meta:{
    message:string;
  }
}

interface NotificationAttributes {
  id: number;
  created_by: number;
  headings: string | null;
  contents: string;
  app_url: string | null;
  is_read: boolean;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  account: string|null;
  deal_id: number;
  dealer_id: number;
  service_provider_id: number | null;
  service_provider_name: string | null;
  dealer_name: string | null;
  owner_last_name: string | null;
  clickable: boolean;
}

interface Notifications {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

interface NotificationResponse {
  data: Notifications[];
  meta: {
    message: string;
    is_all_read: boolean;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  verifyToken: string;
  notifications: any[];
  loading: boolean;
  token: string;

  anchorEl: null | HTMLElement;
  notificationsRceived: Notification[];
  role:string;
  anchorElTwo: HTMLElement | null;
  currentNotificationId:string | null;
  is_all_read:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushnotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNotificationsCallId: string = "";
  getuserDataApiCallId:string="";
  getuserNotificationApiCallId:string="";
  markasReadApiCallId:string="";
  deleteNotiApiId:string="";
  markAllasReadApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      verifyToken: "",
      notifications: [],
      loading: true,
      token: "",
      role:'',

      anchorEl:null,
      notificationsRceived: [],
      anchorElTwo:null,
      currentNotificationId:'',
      is_all_read:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      token = localStorage.getItem('authToken');
      this.getNotifications(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiNotificationsCallId) {
        this.setState({
          notifications: responseJson?.data || [],
          loading: false,
        });
      }
      else if(apiRequestCallId===this.getuserDataApiCallId){
        this.handleUserData(responseJson);
      }
      else if(apiRequestCallId===this.getuserNotificationApiCallId){
        this.handleNotificationResponse(responseJson)
      }
      else if(apiRequestCallId===this.markasReadApiCallId){
        this.handleMarkasRead(responseJson)
      }
      else if(apiRequestCallId===this.deleteNotiApiId){
        this.handleDeleteNotification(responseJson)
      }
      else if(apiRequestCallId===this.markAllasReadApiCallId){
        this.handleMarkAllAsreadResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getUserDataFromBe();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  navigateToCompleteDealPage = (dealId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'MultilevelApproval');
    this.send(msg);
    localStorage.setItem('completeDealId', ''+dealId);
  }

  navigateToConfirmFeesPage = (screen: string, dealId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), screen);
    this.send(msg);
    localStorage.setItem('confirmFeeDealId', ''+dealId);
  }

  notificationClickHandler = (notification: Notification) => {
    if(notification.description.includes('Confirm the fees to allow the work to begin.')){
      this.navigateToConfirmFeesPage('ConfirmFeesDealer', notification.id);
    }
    if(notification.description.includes('You may confirm this amount or edit and send the new amount to dealer.') && notification.clickable){
      this.navigateToConfirmFeesPage('ConfirmFeesProvider', notification.id);
    }
    if(notification.description.includes('Please confirm to complete this deal.')){
      this.navigateToCompleteDealPage(notification.id);
    }
  }

  handleNotificationResponse=(responseJson:NotificationResponse)=>{
    this.setState({is_all_read: responseJson.meta.is_all_read})
    if(this.state.role==='dealer' || this.state.role==='dealer_admin'){
      const receivedRes =  this.extractNotifications(responseJson, 'dealer').sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.setState({notificationsRceived: receivedRes})
    }
    else if(this.state.role==='service_provider' || this.state.role==='service_provider_admin'){
      const receivedResSp =  this.extractNotifications(responseJson, 'sp').sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.setState({notificationsRceived: receivedResSp})
    }
  }

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({role:responseJson.data.attributes.role}, ()=>{
        this.getBeNotification()
      })
    }
  }
  
  getBeNotification=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserNotificationApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_notifications/notifications'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }


  getUserDataFromBe=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

 formatTimestamp=(createdAt:string)=> {
    const timeDifference = new Date().getTime() - new Date(createdAt).getTime();
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(timeDifference / (1000 * 60 * 60 * 60));
    if (hours == 0){
      return `${minutes}m`;
    }
    if (hours < 24) {
      return `${hours}h`;
    } else {
      const days = Math.floor(hours / 24);
      return `${days} days`;
    }
  }
  
  handleMarkAllAsreadResponse=(reponseJson: {message:string})=>{
    if(reponseJson.message==='All notifications marked as read.'){
      this.handleClose();
      this.getBeNotification();
    }
  }

  markAllasRead=async()=>{
    const receivedTokenn = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": receivedTokenn
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markAllasReadApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_notifications/update_multiple'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }
extractNotifications = (apiData:NotificationResponse, gotRole:string)=> {
    return apiData.data.map((notification) => {
      const {
        deal_id:id,
        contents,
        is_read: isRead,
        created_at: createdAt,
        service_provider_name: SpName,
        dealer_name: dealerName,
        owner_last_name: ownerLastName,
        account: profile,
        clickable
      } = notification.attributes;
      
      const title = SpName || dealerName || 'Notification title';
      const name = SpName || dealerName || '';
      const notificationId = notification.id;
     
      const description = contents ||  'Notification description...';
  
      const avatarText = name
        ? name
            .split(' ')
            .map((word: string) => word[0])
            .join('').toUpperCase()
        : ''; 
  
      const timestamp = this.formatTimestamp(createdAt);
  
      return {
        id,
        title,
        description,
        timestamp,
        avatarText,
        isRead,
        ownerLastName,
        notificationId,
        profile,
        createdAt,
        clickable
      };
    });
  }

  handleDeleteNotification=(responseJson: {message:string})=>{
    if(responseJson.message==='Deleted.')
      {
        this.handleCloseMoreVert();
        this.getBeNotification()
      }
    }

  handleMarkasRead=(responseJson: ResponseMarkAsRead)=>{
    if(responseJson.meta.message){
      this.handleCloseMoreVert();
      this.getBeNotification()
    }
  }
   handleClickMoreVert = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, notiId:string | null) => {
    this.setState({anchorElTwo:event.currentTarget, currentNotificationId: notiId});
  };

   handleCloseMoreVert = () => {
    this.setState({anchorElTwo:null, currentNotificationId:''});
  };

  markasRead=async(notiId:string | null)=>{
    const receivedToken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": receivedToken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markasReadApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_notifications/notifications/${notiId}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  deleteNotification=async(notifId:string | null)=>{
    const receivedToken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": receivedToken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNotiApiId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_notifications/notifications/${notifId}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  handleClick = (event:React.MouseEvent<HTMLButtonElement>) => {
    let token = localStorage.getItem('authToken');
    this.setState({ anchorEl: event.currentTarget });
    this.getNotifications(token);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  getColor = (condition:string | boolean, value1: string, value2:string)=>{
    return(condition ? value1 :value2)
  }

  getNotifications(token: string | null) {
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNotificationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRedirection(notification: any) {
    let notification_type =
      notification.attributes?.options?.data?.notification_type;
    switch (notification_type) {
      default:
        break;
    }
  }
  // Customizable Area End
}
